import axios from 'axios'
import firebase from './components/Firebase/Firebase';
import FenceTypeDescriptionData from './fenceTypeData.json'
const uuidv4 = require('uuid/v4');
var path = require('path');
const textureUrl = path.join(__dirname, './fence3D/assets/fence_data')
//const textureUrl = path.join(__dirname, './assets/data/textures')
const fenceTypeData = []
export class DataManager {

  static coordinatesDebugDefault = [47.54892219085843, 8.70977786260039];
  static coordinatesSwitzerlandCenter = [46.899388, 8.203096];
  static coordinatesOfficeDebug = [47.549072, 8.709629];
  static coordinatesUnknownTest = [47.549136, 8.709476];

  static loadFenceTypeTextures(dataLoaded) {
    var convert = require('xml-js');
    // alert('plz was submitted: ' + this.state.value);

    axios.get(textureUrl + '/fences.xml')
      .then(function (res) {

        var result1 = convert.xml2js(res.data, {
          compact: true,
          spaces: 0
        });

        console.log(result1);
        // console.log("DATA " + result1)
        fenceTypeData.length = 0
        fenceTypeData.push(result1)
        dataLoaded()
      })
  }

  static loadFenceTypeDescriptions(dataLoaded) {
  
    // alert('plz was submitted: ' + this.state.value);

    axios.get(textureUrl + '/fenceTypeData.json')
      .then(function (res) {

       

        console.log(res);
        // console.log("DATA " + result1)
      })
  }

  static getFenceTypeData = (id) => {


var filtered =  FenceTypeDescriptionData.filter(
  function(data){ return data.Nummer === ""+id }
);


    return filtered
  }


  static saveUserContactData = (key, description, author, date,
    name,
    email,
    address,
    street,
    plz,
    city,
    country,
    phone,
    usercomment, callback) => {

    const updateRef = firebase.firestore().collection('boards').doc(key);
    updateRef.update({
      description, author, date,
      name,
      email,
      address,
      street,
      plz,
      city,
      country,
      phone,
      usercomment
    }).then((docRef) => {
      callback(null, docRef, null)
    })
      .catch((error) => {
        console.error("Error adding document: ", error);
        callback(error, null, null)
      });


  }


  static saveDataFence = (key, date, user_id, fenceData, userImage, callback) => {

    const updateRef = firebase.firestore().collection('boards').doc(key);
    updateRef.update({
      date, user_id, fenceData, userImage
    }).then((docRef) => {
      callback(null, docRef, null)
    })
      .catch((error) => {
        console.error("Error adding document: ", error);
        callback(error, null, null)
      });

  }

  static saveDataFenceOnly = (key, fenceData, callback) => {

    const updateRef = DataManager.getFirebaseData(key, (data) => {
      updateRef.update({
        fenceData
      }).then((docRef) => {
        callback(null, docRef, null)
      })
        .catch((error) => {
          console.error("Error adding document: ", error);
          callback(error, null, null)
        });
    });
  }

  static saveDataSettingsOnly = (key, settings, callback) => {
    DataManager.getFirebaseData(key,  (doc) =>  {
      if (doc !== undefined) {
    doc.ref.update({
        settings
      }).then((docRef) => {
        callback(null, docRef, null)
      })
        .catch((error) => {
          console.error("Error adding document: ", error);
          callback(error, null, null)
        });
      }
    });
  }

  static getSettingsFirebase = (key, callback) => {
    DataManager.getFirebaseData(key,  (doc) =>  {
      if (doc !== undefined) {
        console.error("getSettingsFirebase: ", doc);
          callback(doc)
      }
    });
  }

  static getVariation = (folderNumber, fencesFromCategory, variationIndex = 0) => {

    console.log("folderNumber: " + folderNumber + " variationIndex: " + variationIndex);
    var variation = ''
    if (fencesFromCategory[folderNumber].variation instanceof Array) {
      variation = fencesFromCategory[folderNumber].variation[variationIndex]._cdata
    } else {
      variation = fencesFromCategory[folderNumber].variation._cdata
    }
    return variation
  }

  static getSliderItems(fenceCatType, fenceType, fenceTypeVariationIndex = 0, fenceTypeVariation) {

    var sliderItems = []
    var fenceData
    if (fenceTypeData.length === 1) {
      fenceData = fenceTypeData[0].data.fence

      if (fenceData !== undefined) {
        const fencesFromCategory = fenceData.filter((fd) => {
          return fd.usage._cdata.includes(fenceCatType);
        });

        for (var i = 0; i < fencesFromCategory.length; i++) {
          var variation = DataManager.getVariation(i, fencesFromCategory)

          var folder = fencesFromCategory[i].folder._cdata
          var imageSrc = textureUrl + "/" + folder + "/" + variation + "/element.png"

          sliderItems.push(imageSrc)
        }

        var index = 0
        var ft = fenceType
        var fenceIndex = 0

        for (var fence in sliderItems) {


          var startIndex = sliderItems[fence].indexOf("fence_data/")
          var part = sliderItems[fence].substr(startIndex + 11, 3)

          if (part === ft) {
            fenceIndex = index
          } else {
            index = index + 1
          }
        }

        return { fencesFromCategory: fencesFromCategory, sliderItems: sliderItems, selectedFenceTypeIndex: fenceIndex }

      }
    }

    return null

  }


  static saveFenceDataToLocalStorage = (fenceData, docID) => {
    var objToSave = ""
    var fenceType = ""
    var usage = ""
    var fenceHeight = ""
    var variation = ""
    var fenceCenterPos = ""
    //save fence data to local storage
    if (fenceData.length === 0) {
      fenceData = DataManager.getEmptyFenceData()
    }
    var jsonData = JSON.parse(fenceData)
    var seen = [];
    fenceType = jsonData.config.fence.fenceType
    usage = jsonData.config.fence.usage
    fenceHeight = jsonData.config.fence.height
    variation = jsonData.config.fence.variation
    fenceCenterPos = jsonData.coordinates.center
    objToSave = JSON.stringify(jsonData.coordinates_gm.fence, function (key, val) {
      if (val != null && typeof val == "object") {
        if (seen.indexOf(val) >= 0) {
          return;
        }
        seen.push(val);
      }
      return val;
    });

    console.log("FENCE DATA " + objToSave)

    DataManager.saveToLocalStorage("fenceType", fenceType)
    DataManager.saveToLocalStorage("fenceCat", usage)
    DataManager.saveToLocalStorage("boardId", docID)
    DataManager.saveToLocalStorage("path", objToSave)
    DataManager.saveToLocalStorage("fenceData", fenceData)
    DataManager.saveToLocalStorage("fenceHeight", fenceHeight)
    DataManager.saveToLocalStorage("fenceTypeVariation", variation)
    DataManager.saveToLocalStorage("fenceCenterPos", JSON.stringify(fenceCenterPos))

  }

  static deleteProject = () => {

    DataManager.removeFromLocalStorage("fenceType")
    DataManager.removeFromLocalStorage("fenceCat")
    DataManager.removeFromLocalStorage("boardId")
    DataManager.removeFromLocalStorage("path")
    DataManager.removeFromLocalStorage("fenceData")
    DataManager.removeFromLocalStorage("fenceHeight")
    DataManager.removeFromLocalStorage("fenceTypeVariation")
    DataManager.removeFromLocalStorage("fenceCenterPos")

    DataManager.removeFromLocalStorage("fenceLength")

    DataManager.removeFromLocalStorage("backgroundPhoto")

    DataManager.removeFromLocalStorage("fenceTypeVariation")
    DataManager.removeFromLocalStorage("locationSet")
  }

  static deleteProjectOnServer = () => {


  }

  static getFenceImageSrc = () => {
   // var textureUrl = path.join(__dirname, './assets/data/textures')
    var folder = localStorage.getItem("fenceType")
    var variation =  localStorage.getItem("fenceTypeVariation")
    var imageSrc = textureUrl + "/" + folder + "/" + variation + "/element.png"
    
    return  imageSrc

  }


  static getFencePostImageSrc = () => {
    // var textureUrl = path.join(__dirname, './assets/data/textures')
     var folder = localStorage.getItem("fenceType")
     var variation =  localStorage.getItem("fenceTypeVariation")
     var imageSrc = textureUrl + "/" + folder + "/" + variation + "/post.jpg"
     
     return  imageSrc
 
   }

  static getEmptyFenceData = () => {

    var emptyFenceData = '{"config":{"axisHelper":{"enabled":false},"drawBoundary":{"enabled":false},"groundHelper":{"enabled":false,"wireframe":false,"texture":""},"skyboxHelper":{"enabled":false,"wireframe":false,"textures":""},"debug":{"enabled":false},"fps":{"enabled":false},"posts":{"showPosts":false},"gate":{"height":200,"texture":"./assets/textures/gate_01.png"},"camera":{"pos":{"x":0,"y":0,"z":0},"coordinates":{"lat":46.899388,"lng":8.203096}, "radius":1784.982,"angle":89.85,"rotation":23.7,"drawRange":55000},"group":{"info":["Ensure pos.y is at least 1/2 fence height","Centering X or Z will ignore pos x or z"],"centerX":true,"centerZ":true,"centerXOffset":0,"centerZOffset":0,"pos":{"x":0,"y":100,"z":0},"rotation":{"x":0,"y":0,"z":0}},'
      + '"fence": {'
      + '"variation": "20_20",'
      + '"usage": "0",'
      + '"fenceType":"101",'
      + '"height": 400,'
      + '"texture": "",'
      + '"textureScales": [[0,500,400],[500,1000,350],[1000,1500,300],[1500,2000,250],[2000,2500,200],[2500,3000,150],[3000,3500,100],[3500,4000,75]]'
      + '}'
      + '},'
      + '"coordinates": {"scale":{"multiplier":10100000},'
      + '"center":{"lat":46.899388,"lng":8.203096},'
      + '"fence":[],'
      + '"doors":[]'
      + '},'
      + '"coordinates_gm":{'
      + '"fence":[],'
      + '"doors":[]'
      + ' }'
      + '}'

    return emptyFenceData
  }

  static updateLocalFenceData = (newPathToSaveFirst = "") => {

    if (newPathToSaveFirst.length > 0) {
      console.log("newPathToSaveFirst.length: " + newPathToSaveFirst.length)
      DataManager.saveToLocalStorage("path", newPathToSaveFirst);
    }

    var localFenceData = DataManager.getFromLocalStorage("fenceData")


    if (localFenceData == null || localFenceData.length === 0) {

      localFenceData = DataManager.getEmptyFenceData
    }

    console.log("LOCAL FENCE DATA: " + localFenceData)
    var path = JSON.parse(DataManager.getFromLocalStorage("path"));

    if (path != null && path.length > 0) {
      var newPathContainer = []
      for (var item in path) {
        var newPath = []
        console.log("ITEM: " + path[item].lat)
        // newPath.push("ad")
        newPath.push(path[item].lat)
        newPath.push(path[item].lng)
        newPath.push(uuidv4())
        newPathContainer.push(newPath)
      }

      var fenceData = JSON.parse(localFenceData)
      var coordinates = fenceData.coordinates
      var coordinates_gm = fenceData.coordinates_gm
      var fenceConfig = fenceData.config.fence

      console.log("coordinates: " + coordinates.fence + " fenceConfig: " + fenceConfig)

      coordinates.fence = newPathContainer
      coordinates_gm.fence = path

      fenceData.coordinates = coordinates
      fenceData.coordinates_gm = coordinates_gm
      fenceData.coordinates.center = JSON.parse(DataManager.getFromLocalStorage("fenceCenterPos"))
      fenceConfig.usage = DataManager.getFromLocalStorage("fenceCat")
      fenceConfig.fenceType = DataManager.getFromLocalStorage("fenceType")
      fenceConfig.height = DataManager.getFromLocalStorage("fenceHeight")
      fenceConfig.variation = DataManager.getFromLocalStorage("fenceTypeVariation")

      var seen = [];
      var objToSave = JSON.stringify(fenceData, function (key, val) {
        if (val != null && typeof val == "object") {
          if (seen.indexOf(val) >= 0) {
            return;
          }
          seen.push(val);
        }
        return val;
      });
      DataManager.saveToLocalStorage("fenceData", objToSave);
    }
  }

  static updateFenceDataSimple = (fenceData) => {
    var seen = [];
    var objToSave = JSON.stringify(fenceData, function (key, val) {
      if (val != null && typeof val == "object") {
        if (seen.indexOf(val) >= 0) {
          return;
        }
        seen.push(val);
      }
      return val;
    });
    DataManager.saveToLocalStorage("fenceData", objToSave);
  }

  static updateLocalFenceDataCamera = (pos, radius, angle, rotation = null, drawRange, scaleMultiplier, cameraCoordinates = null) => {

    var localFenceData = DataManager.getFromLocalStorage("fenceData")
    if (localFenceData == null || localFenceData.length === 0) {
      return
    }

    var fenceData = JSON.parse(localFenceData)

    var cameraConfig = fenceData.config.camera
    if (cameraCoordinates != null) {
      cameraConfig.coordinates = cameraCoordinates
    }

    cameraConfig.pos.x = pos[0]
    cameraConfig.pos.y = pos[1]
    cameraConfig.pos.z = pos[2]
    cameraConfig.radius = radius
    cameraConfig.angle = angle
    if (rotation != null) {
      cameraConfig.rotation = rotation
    }

    cameraConfig.drawRange = drawRange

    var coordinates = fenceData.coordinates
    var coordinatesScale = coordinates.scale
    coordinatesScale.multiplier = scaleMultiplier


    console.log("coordinates: " + cameraConfig.pos + " fenceConfig: " + cameraConfig)

    var seen = [];
    var objToSave = JSON.stringify(fenceData, function (key, val) {
      if (val != null && typeof val == "object") {
        if (seen.indexOf(val) >= 0) {
          return;
        }
        seen.push(val);
      }
      return val;
    });
    DataManager.saveToLocalStorage("fenceData", objToSave);

  }

  static updateLocalFenceDataGroup = (centerX, centerXOffset, centerZ, centerZOffset, pos, rotation) => {

    var localFenceData = DataManager.getFromLocalStorage("fenceData")
    if (localFenceData == null || localFenceData.length === 0) {
      return
    }

    var fenceData = JSON.parse(localFenceData)

    var groupConfig = fenceData.config.group
    groupConfig.pos.x = pos[0]
    groupConfig.pos.y = pos[1]
    groupConfig.pos.z = pos[2]
    groupConfig.rotation.x = rotation[0]
    groupConfig.rotation.y = rotation[1]
    groupConfig.rotation.z = rotation[2]
    groupConfig.centerX = centerX
    groupConfig.centerXOffset = centerXOffset
    groupConfig.centerZ = centerZ
    groupConfig.centerZOffset = centerZOffset

    var seen = [];
    var objToSave = JSON.stringify(fenceData, function (key, val) {
      if (val != null && typeof val == "object") {
        if (seen.indexOf(val) >= 0) {
          return;
        }
        seen.push(val);
      }
      return val;
    });
    DataManager.saveToLocalStorage("fenceData", objToSave);

  }

  static getMapFenceDataFromLocalStorage = () => {
    console.log("DATA " + this)
    var pathString = DataManager.getFromLocalStorage("path")
    if (pathString != null && pathString.length > 0) {
      return JSON.parse(DataManager.getFromLocalStorage("path"));
    }
    return JSON
  }

  static getFenceDataFromLocalStorage = () => {
    console.log("DATA " + this)
    var pathString = DataManager.getFromLocalStorage("fenceData")
    if (pathString != null && pathString.length > 0) {
      return JSON.parse(DataManager.getFromLocalStorage("fenceData"));
    }
    return JSON
  }

  static getFenceCatName = () => {
    var fenceCat = Number(DataManager.getFromLocalStorage("fenceCat"))
    var fenceCatName = ""
    switch (fenceCat) {
      case 0:
        fenceCatName = "Heim & Garten"
        break;
      case 1:
        fenceCatName = "Sicht-/Lärmschutz"
        break;
      case 2:
        fenceCatName = "mehr Sicherheit"
        break;
      case 3:
        fenceCatName = "Tierhaltung"
        break;
      default:
        break;
    }

    return fenceCatName

  }

  static getFenceHeight = () => {
    var fenceHeight = Number(DataManager.getFromLocalStorage("fenceHeight"))
    return fenceHeight / 200

  }

  static getFenceLength = () => {
    var fenceLength = Number(DataManager.getFromLocalStorage("fenceLength")).toFixed(2)
    return fenceLength
  }

  static consoleLog(str) {
    console.log(str)
  }
// STORAGE / JSON 
  static saveToLocalStorage = (key, val, info = null) => {
    localStorage.setItem(key, val)
  }

  static removeFromLocalStorage = (key) => {
    localStorage.removeItem(key)
  }

  static getFromLocalStorage = (key, info = null) => {
    var val = localStorage.getItem(key)

    return val
  }


  static convertPathForGoogleMaps = () => {
  }

  
  static getFirebaseData = (id, callback) => {
    if(id === "") {
      callback(undefined)
    } else {
      firebase.firestore().collection('boards').doc(id).get().then((doc) => {
        if (doc.exists) {
            callback(doc)
        } else {
          callback(undefined)
        }
      });
    }

  }

  static ConvertDMSToDD(degrees, minutes, seconds, direction) {

    var dd = degrees + (minutes / 60) + (seconds / 3600);

    if (direction === "S" || direction === "W") {
      dd = dd * -1;
    }

    return dd;
  }

  static ConvertDecimalCoordinates(exifData) {
    if (exifData.GPSLatitude == null) {
      return null
    }
    var latDegree = exifData.GPSLatitude[0];
    var latMinute = exifData.GPSLatitude[1];
    var latSecond = exifData.GPSLatitude[2];
    var latDirection = exifData.GPSLatitudeRef;

    var latFinal = this.ConvertDMSToDD(latDegree, latMinute, latSecond, latDirection);
    console.log(latDegree + "" + latFinal);

    // Calculate longitude decimal
    var lonDegree = exifData.GPSLongitude[0];
    var lonMinute = exifData.GPSLongitude[1];
    var lonSecond = exifData.GPSLongitude[2];
    var lonDirection = exifData.GPSLongitudeRef;

    var lonFinal = this.ConvertDMSToDD(lonDegree, lonMinute, lonSecond, lonDirection);
    console.log(lonFinal);

    return [latFinal, lonFinal]

  }

  static getDistancePixel(position1, position2) {
    var distx = Number(position1.lng() - position2.lng())
    var disty = Number(position1.lat() - position2.lat())


    distx = distx * 25000000
    disty = disty * 25000000

    var distCalc = Math.abs(distx) + Math.abs(disty)

    console.log("distx" + distx + " disty" + disty + "distCalc " + distCalc)

    return distCalc
  }
  /********** Unity converter */

  //-------------------------------------------------------------
  //
  // Hier folgt der Zauber 2 :-)
  // Aus allen Pfosten-Koordinaten werden hier die Pixelkoordinaten
  // sowie das Zentrum berechnet.
  // Dazu einfach alle Pfosten in folgender Art als String übergeben:
  // Lat,Lng,Lat,Lng,Lat,Lng.....
  //
  //-------------------------------------------------------------

  static convertLatLngToCoords(getLatLngArray, fixPointArray, getPpCm = 985) {
    var t_returndata = "";
    var t_coordsArray = getLatLngArray;
    var t_latCenter = 0;
    var t_lngCenter = 0;

    var t_fixPointArray = fixPointArray
    var t_latFixPoint = 0;
    var t_lngFixPoint = 0;

    var t_elements = 0;


    // Calculate center
    for (var t_i = 0; t_i < t_coordsArray.length; t_i += 3) {
      if (t_coordsArray[t_i] && t_coordsArray[t_i + 1]) {
        t_elements++;
        t_latCenter += parseFloat(t_coordsArray[t_i]);
        t_lngCenter += parseFloat(t_coordsArray[t_i + 1]);
      }
    }

    t_latCenter = t_latCenter / t_elements;
    t_lngCenter = t_lngCenter / t_elements;


    t_latFixPoint = (parseFloat(t_fixPointArray[1]) - t_lngCenter) * getPpCm * 1000;
    t_lngFixPoint = -(parseFloat(t_fixPointArray[0]) - t_latCenter) * getPpCm * 1000;

    t_latFixPoint = this.precise_round(t_latFixPoint, 2);
    t_lngFixPoint = this.precise_round(t_lngFixPoint, 2);

    var t_PostString = "";
    var t_startpostSet = false;

    // Calculate post coords relative to center
    for (t_i = 0; t_i < t_coordsArray.length; t_i += 3) {
      if (t_coordsArray[t_i] && t_coordsArray[t_i + 1]) {
        // Calculate value
        var t_latCoord = (parseFloat(t_coordsArray[t_i + 1]) - t_lngCenter) * getPpCm * 1000;
        var t_lngCoord = -(parseFloat(t_coordsArray[t_i]) - t_latCenter) * getPpCm * 1000;
        var t_postType = t_coordsArray[t_i + 2]
        /* var t_latCoord = (parseFloat(t_coordsArray[t_i])-t_latCenter)*getPpCm*1000;
         var t_lngCoord = (parseFloat(t_coordsArray[t_i+1])-t_lngCenter)*getPpCm*1000;*/

        // Round to two decimals
        t_latCoord = this.precise_round(t_latCoord, 2);
        t_lngCoord = this.precise_round(t_lngCoord, 2);

        this.consoleLog("t_latCoord JS:" + t_latCoord);
        this.consoleLog("t_lngCoord JS:" + t_lngCoord);
        this.consoleLog("t_postType JS:" + t_postType);

        /* if(t_startpostSet){
             t_PostString = t_PostString+"Post:"+t_latCoord+","+t_lngCoord+",normpost;";
         }else{
             t_PostString = t_PostString+"Post:"+t_latCoord+","+t_lngCoord+",startpost;";
             t_startpostSet=true;
         }*/
        t_PostString = t_PostString + "Post:" + t_latCoord + "," + t_lngCoord + "," + t_postType + ";";
      }
    }

    //t_returndata = "Lat:"+t_latCenter+";Lng:"+t_lngCenter+";PpCm:"+985+";"+t_PostString;
    t_returndata = "Lat:" + t_latCenter + ";Lng:" + t_lngCenter + ";PpCm:" + 985 + ";Fixpoint:" + t_latFixPoint + "," + t_lngFixPoint + ";" + t_PostString;


    this.consoleLog("t_returndata JS:" + t_returndata);

    return t_returndata;
  }

  static precise_round(value, decPlaces) {
    var val = value * Math.pow(10, decPlaces);
    var fraction = (Math.round((val - parseInt(val)) * 10) / 10);

    //this line is for consistency with .NET Decimal.Round behavior
    // -342.055 => -342.06
    if (fraction === -0.5) fraction = -0.6;

    val = Math.round(parseInt(val) + fraction) / Math.pow(10, decPlaces);
    return val;
  }

}
