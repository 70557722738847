import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import './chooseStyle.css';

import PropTypes from "prop-types";
import Slider from '@material-ui/core/Slider';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { DataManager } from './../../DataManager'

import Tooltip from '@material-ui/core/Tooltip';
var path = require('path');
const textureUrl = path.join(__dirname, './fence3D/assets/fence_data')
const PrettoSlider = withStyles({
    root: {
        marginTop:'25px',
      color: 'primary',
      height: 3,
    },
    thumb: {
      height: 24,
      width: 24,
      marginTop: -8,
      marginLeft: -12,
      right:5,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus,&:hover,&$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-10% + 1px)',
    },
    track: {
      height: 8,
      borderRadius: 1,
      margin:-5
    },
    rail: {
      height: 8,
      borderRadius: 1,
      margin:-5
    },
  })(Slider);

const styles = theme => ( {
    root: {
        display: 'inline',
        height: '100%',
        padding:'15px'
    }
});



  const marks = [
    {
        value: 0.5,
        label: '0.5 m',
      },
    {
      value: 1,
      label: '1 m',
    },
    {
      value: 1.5,
      label: '1.5 m',
    },
    {
      value: 2,
      label: '2 m',
    },
    {
      value: 2.5,
      label: '2.5 m',
    },
  ];
class Choose extends Component {

    fenceData
    selectedFences

    constructor(props) {
        super(props)

        this.state = {
            firebase:props.firebase,
            boardid:props.boardid,
            fenceSliderHeight: 220,
            componentContainerHeight:0,
            sliderValue: 1.5,
            items: Element,
            sliderItems: [],
            fenceCatType: 0,
            selectedFenceTypeIndex: 0,
            fencesForSelectedCategory: Element,
            thumbYPos: 0,
            fenceType: "",
            fenceTypeVariation: ""
        }

        // eslint-disable-next-line no-undef
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        console.log("PROPS " + props.testid)
    }


    updateWindowDimensions() {
        const height =
          window.innerHeight -
          document.getElementById("ViewHeaderContainer").clientHeight -
          document.getElementById("IntroText").clientHeight -
          document.getElementById("appBar").clientHeight
        this.setState({ width: window.innerWidth, componentContainerHeight: height });
      }

    componentWillUnmount() {
         DataManager.updateLocalFenceData()
        window.removeEventListener("resize", this.updateWindowDimensions);

      }

    componentDidMount() {
       
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
       var _this = this
        var fenceCat = parseInt(DataManager.getFromLocalStorage('fenceCat'))
        var fenceType = DataManager.getFromLocalStorage('fenceType')
        var fenceHeight = DataManager.getFromLocalStorage('fenceHeight')
        var fenceTypeVariation = DataManager.getFromLocalStorage('fenceTypeVariation')

        this.setState({
            sliderValue: fenceHeight / 200
        })

        if (fenceCat === null || fenceCat === undefined) {
            fenceCat = 0
        }

        if (fenceType === null || fenceType === undefined) {
            fenceType = "0"
        }
            this.setState( {
                    fenceCatType: fenceCat,
                    fenceType: fenceType,
                    fenceTypeVariation:fenceTypeVariation
            }, () => {

                DataManager.loadFenceTypeTextures(function() {
                   _this.createSliderItems(false)
                })
    
            });
    }

    handleChangeFenceType = (event, value) => {
     
        this.setState( {
                fenceCatType: value,
                selectedFenceTypeIndex:0,
                fenceType:0
            
        }, () => {
            DataManager.saveToLocalStorage('fenceCat', value.toString())
     
            this.createSliderItems(true)
        
        });
    };

    createItems(sliderItems) {

        return sliderItems.map(this.createItem);
    }

    createItem(item) {

        return <div id="carlouselItem" > < img src={item} /> </div>
    }

    createSliderItems(isInternal) {

        var obj = DataManager.getSliderItems(this.state.fenceCatType, this.state.fenceType, this.state.selectedFenceTypeIndex, this.state.fenceTypeVariation)

        console.log("this.state.selectedFenceTypeIndex: "+this.state.selectedFenceTypeIndex)
        if(!isInternal) {
                obj.sliderItems[obj.selectedFenceTypeIndex] = textureUrl + "/" + this.state.fenceType + "/" + this.state.fenceTypeVariation + "/element.png"
        } else {

        }

        this.setState({
            sliderItems: obj.sliderItems,
            fencesForSelectedCategory: obj.fencesFromCategory,
            selectedFenceTypeIndex: obj.selectedFenceTypeIndex,
            fenceType:obj.fencesFromCategory[obj.selectedFenceTypeIndex].folder._cdata,
        }, () => {
         

           // if (parseInt(this.state.selectedFenceTypeIndex) <= 0) {
            //    this.onCarouselChange(this.state.selectedFenceTypeIndex)    
           // }
      
            DataManager.saveToLocalStorage('fenceType', obj.fencesFromCategory[obj.selectedFenceTypeIndex].folder._cdata)
            DataManager.saveToLocalStorage('fenceTypeVariation', this.state.fenceTypeVariation)
            DataManager.updateLocalFenceData()
            this.render()
        });
    }

    onCarouselChange = selectedIndex => {
        
        // this.updateWindowDimensions()
        if (this.state.fencesForSelectedCategory[selectedIndex] !== undefined) {
           
            console.log('onCarouselChange ' + selectedIndex);
            var fenceType = this.state.fencesForSelectedCategory[selectedIndex].folder._cdata
           
      
            var variation = DataManager.getVariation(selectedIndex, this.state.fencesForSelectedCategory)
            DataManager.saveToLocalStorage('fenceTypeVariation', variation)
            this.setState({
                fenceTypeVariation:variation
               })
  
       
    
            DataManager.saveToLocalStorage('fenceType', fenceType)
            this.setState({
                fenceType:fenceType, 
                selectedFenceTypeIndex:selectedIndex
               }, () => {
                DataManager.updateLocalFenceData()
                this.createSliderItems()
            });

        } 

    }



    variationIndex = 0
    
    onCarouselItemClick = (selectedIndex) => {
        // this.updateWindowDimensions()
        if (this.state.fencesForSelectedCategory[selectedIndex] !== undefined) {
          
            let variationCount = this.state.fencesForSelectedCategory[selectedIndex].variation.length

            if( this.variationIndex < variationCount - 1) {
                this.variationIndex = this.variationIndex + 1
            } else {
                this.variationIndex = 0
            }
           
            var variation = DataManager.getVariation(selectedIndex, this.state.fencesForSelectedCategory, this.variationIndex)
            DataManager.saveToLocalStorage('fenceTypeVariation', variation)
            let loadedFenceType = DataManager.getFromLocalStorage('fenceType')
            console.log("SELECTED INDEX "+selectedIndex+" loadedFenceType: "+loadedFenceType)
            let newSliderItems = this.state.sliderItems
            newSliderItems[selectedIndex] = textureUrl + "/"+loadedFenceType+"/" + variation + "/element.png"
            this.setState({
                sliderItems: newSliderItems
               })
        }
    }


    handleChangeSlider = (event, value) => {
        this.setState({
            sliderValue: value
        });
        // this.props.handleChangeFenceType(this.state)
        DataManager.saveToLocalStorage("fenceHeight", value * 200)
        DataManager.updateLocalFenceData()
    };

    handleChangeHeight = (e) => {
        this.setState(function (state, props) {
            return {
                value: e
            };
        });
    }

    getZauntypName = () => {
if (this.state.fenceType.length > 0 && DataManager.getFenceTypeData(this.state.fenceType)[0] !== undefined) {
       return  DataManager.getFenceTypeData(this.state.fenceType)[0].Zauntyp
    } else {
        return ""
    }
}

    render() {

        const { classes } = this.props;

        const ShowFences = (itemsData) => {

            return (
             
                <div className='ContainerFenceSlider' style={{ height: this.state.componentContainerHeight }}>
                 
                    <Carousel dynamicHeight={false} selectedItem={this.state.selectedFenceTypeIndex} onClickItem={(index, selectedItem) => this.onCarouselItemClick(index)} onChange={(index) => this.onCarouselChange(index)} showThumbs={false} className="FenceCarousel"  useKeyboardArrows={true} emulateTouch={true}>
                    
                        {this.createItems(this.state.sliderItems)}
                      
                    </Carousel>
                 
                </div>
              
             
            )
        }

        return (
            <div  className={classes.root} >
           
                <div id="IntroText">Wählen Sie ihren Wunsch-Zaun aus unserem Sortiment </div>

                <AppBar position="static" color="default"  className={classes.tab}  id="appBar">
                    <Tabs
                        value={parseInt(this.state.fenceCatType)}
                        onChange={this.handleChangeFenceType}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"

                    >
                        <Tab label="Heim & Garten" />
                        <Tab label="Sicht-/Lärmschutz" />
                        <Tab label="mehr Sicherheit" />
                        <Tab label="Tierhaltung" />
                    </Tabs>
                </AppBar>

                <div className="ComponentsContainer" style={{ height: this.state.componentContainerHeight}}>


                    <div className='ContainerChooseHeight' style={{ height: this.state.fenceSliderHeight}}>
                        <h3>Zaun-Höhe:</h3>
                 
                        <Tooltip
    placement="left"
      title={this.state.sliderValue}
    >
            <PrettoSlider  className={classes.slider}          
                                value={this.state.sliderValue}
                                onChange={this.handleChangeSlider}
                                max={2.5}
                                min={0.5}
                                step={0.1}
                                orientation="vertical"
                                marks={marks}
                    
                            />
    </Tooltip>

                    
                        </div>

                        
                    <div className="ContainerChooseFence" >
                    
                        <h3>Zaun-Typ:</h3>
                     <div style={{fontSize:18}}>  {this.getZauntypName()}</div> 
                 <div></div>  
                          <div style={{fontSize:12}}>klick auf Zaun für Farbwechsel</div>  
                              <div></div>  
                                  <div></div> 
                                     <div></div>  
                                  <div></div>  
                         
                        <ShowFences> </ShowFences>
                       
                    </div>
                   
                </div>
              
            </div>

        )
    }
}

Choose.propTypes = {
    selectedFenceType: PropTypes.string,
    handleChangeFenceType: PropTypes.func,
    testid: PropTypes.string,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Choose);