
import * as firebase from 'firebase';
//import 'firebase/firestore'
const settings = {timestampsInSnapshots: true};
/*
const config = {
  apiKey: "AIzaSyBcBUgp0WJYOcPZjRPaY_-qS8ZnE0dxXMw",
  authDomain: "training01-26482.firebaseapp.com",
  databaseURL: "https://training01-26482.firebaseio.com",
  projectId: "training01-26482",
  storageBucket: "training01-26482.appspot.com",
  messagingSenderId: "560170724335"
};*/
const config = {
  apiKey: "AIzaSyCFuhAVeEaRFZXfxtCJ8ZhU15cnf0bOSUw",
  authDomain: "zaunplaner-1525772594816.firebaseapp.com",
  databaseURL: "https://zaunplaner-1525772594816.firebaseio.com",
  projectId: "zaunplaner-1525772594816",
  storageBucket: "zaunplaner-1525772594816.appspot.com",
  messagingSenderId: "945645339002"
};


firebase.initializeApp(config)

firebase.firestore().settings(settings);

firebase.firestore().enablePersistence()
  .catch(function(err) {

    console.log("ERROR enablePersistence "+err.code)

      if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
          console.log("ERROR failed-precondition "+err)
      } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
          console.log("ERROR unimplemented "+err)
      }
  });





  // Configure the email transport using the default SMTP transport and a GMail account.
// For other types of transports such as Sendgrid see https://nodemailer.com/transports/
// TODO: Configure the `gmail.email` and `gmail.password` Google Cloud environment variables.
//firebase.functions().config()


  

export default firebase;
