import React, { Component } from 'react';
import GoogleMapReact from "google-map-react";
import { DataManager } from '../../DataManager'

class MapDetails extends Component {
    static coordinatesDebugDefault = [47.54892219085843, 8.70977786260039];
    static coordinatesSwitzerlandCenter = [46.899388, 8.203096];
    static coordinatesOfficeDebug = [47.549072, 8.709629];
    static coordinatesUnknownTest = [47.549136, 8.709476];


    constructor(props) {
        super(props);
        this.state = {
          open: false,
          width: 300,
          height: 300,
          tabValue: 0,
          polyline: [],
          loadedMap: null,
          dialogOpen: false,
          dialogDoorOpen: false,
          dialogDoorDescription: false,
          dialogPhotoOpen: false,
          dialogLocationSearchOpen: false,
          toolDescription: '',
          toolDetailDescription: '',
          isDragingPost: false,
          showInfos: false,
          showDoorMarkers: false, locationIsSet: false
    
        };

      }

      onGoogleMapsLoaded = (map, maps) => {

        maps.Polyline.prototype.getBounds = function () {
          var bounds = new maps.LatLngBounds();
          this.getPath().forEach(function (item, index) {
            bounds.extend(new maps.LatLng(item.lat(), item.lng()));
          });
          return bounds;
        };
        map.setTilt(0)
    
        this.loadedMaps = maps;
        this.loadedMap = map;
    

        this.drawExistingPolyline()
     
    
      };

 drawExistingPolyline = () => {
    var path = DataManager.getMapFenceDataFromLocalStorage()

    if (path != null && path.length > 0) {
   
      var savedPolyline = new this.loadedMaps.Polyline({
        path: path,
        geodesic: false,
        strokeColor: "#FFFFFF",
        fillColor: "#000000",
        strokeOpacity: 1.0,
        strokeWeight: 3,
        zIndex: 1,
        draggable: false,
        editable: false,

      });

      this.setState({ polyline: savedPolyline });

      savedPolyline.setMap(this.loadedMap);
      this.setBoundsToViewAll()
    }


  }
  setBoundsToViewAll = () => {
    var bounds = new this.loadedMaps.LatLngBounds();


    if (this.checkPolylineHasContent()) {
      this.state.polyline.getPath()
        .getArray()
        .forEach(function (a) {
          return bounds.extend(a);

        })
    }
    this.loadedMap.fitBounds(bounds);

  }

  checkPolylineHasContent = () => {
    // console.log("POLYLINE: "+this.state.polyline)
    if (this.state.polyline.length === 0) {
      return false
    }

    return true
  }
  static defaultProps = {
    center: {
      lat: this.coordinatesSwitzerlandCenter[0],
      lng: this.coordinatesSwitzerlandCenter[1]
    },
    zoom: 8
  };
  createMapOptions = {
    panControl: false,
    mapTypeControl: false,
    scrollwheel: false,
    scaleControl: false,
    streetViewControl: false,
    tilt:0,
    rotateControl: false,
    mapTypeId:'satellite',
    
    styles: [
      {
        stylers: [
          { saturation: -60 },
          { gamma: 1 },
          { lightness: 1 },
          { visibility: "on" }
        ]
      }
    ]
  };

  render(props) {
    return (

   

        <div id="MapContainer" style={{ height: this.state.height - 10 }}>
 

          <GoogleMapReact id="capture"
            options={this.createMapOptions}
            bootstrapURLKeys={{
              key: "AIzaSyCf5VarQ3amrnPXN2xg7HsQFXJgvhToWZU",
              libraries: ["drawing", "places", "geometry"].join(",")
            }}
            defaultCenter={this.props.center}
            tilt={0}
            defaultZoom={this.props.zoom}
            onGoogleApiLoaded={({ map, maps }) =>
              this.onGoogleMapsLoaded(map, maps)
            }
            yesIWantToUseGoogleMapApiInternals
          />

        </div>

    );
  }

}



export default MapDetails;