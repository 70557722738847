import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createMuiTheme } from '@material-ui/core/styles';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import './App.css';
import Header from './components/Header';
import StartPage from './components/StartPage';
import Choose from './components/ui/Choose'

import PlanC from './components/ui/PlanC'
import Visualize from './components/ui/Visualize'
import ContactForm from './components/ui/ContactForm'
import PartnerFinder from './components/ui/PartnerFinder'
import Anleitung from './components/ui/Anleitung'
import firebase from './components/Firebase/Firebase';
import { DataManager } from './DataManager'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import FenceTypeData from './fenceTypeData.json'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#768f0d'
    },
    secondary: {
      main: '#9fc01d'
    }
  }


});


class App extends Component {



  
  _isMounted = false;
  constructor(props) {
    super(props);

    this.unsubscribe = null;
    this.state = {
      dialogLoadProjectOpen: false,
      activePage: '',
      boardid: '',
      title: '',
      description: '',
      author: '',
      date: new Date(), name: '', email: '', address: '', user_id: '', fenceData: '', fenceReady: false
    }


    console.log("FenceTypeData: "+DataManager.getFenceTypeData('580')[0].Zauntyp)

  }

  onCollectionUpdate = (querySnapshot) => {
    console.log("onCollectionUpdate")
    const boards = [];
    querySnapshot.forEach((doc) => {
      const { title, description, author, date, name, email, address, user_id, fenceData } = doc.data();
      boards.push({
        key: doc.id,
        doc, // DocumentSnapshot
        title,
        description,
        author,
        date, name, email, address, user_id, fenceData,
      });
    });
    this.setState({
      boards
    });

  }
  componentDidMount() {
    this._isMounted = true;
    // this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    console.log("componentDidMount App.js")




    var urlBoardid = this.getUrlVars()["fb"]

    if (urlBoardid != null && urlBoardid !== undefined) {
      this.setState({
        dialogLoadProjectOpen: true
      })
    } else {
      this.loadLocalProjekt()
    }
  }

  loadLocalProjekt = () => {
    var localBoardid = DataManager.getFromLocalStorage("boardId")
    if (localBoardid != null && localBoardid !== undefined) {
      this.setState({
        boardid: localBoardid
      }, () => {
        this.getExistingBoard()
      });
    } else {
      this.addNewBoard()
    }
  }

  // if url parmeter solution ist necce
  readAndProccessUrl = () => {

    console.log("ID: " + this.getUrlVars()["fb"])

    var boardid = this.getUrlVars()["fb"]

    if (boardid != null && boardid !== undefined) {
      this.setState({
        boardid: this.getUrlVars()["fb"]
      }, () => {

        this.getExistingBoard()
      });

    } else {
      this.addNewBoard()
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
    //   this.unsubscribe()
  }

  getExistingBoard() {

    /*firebase.firestore().collection('boards').doc(this.state.boardid).get().then((doc) => {
      if (doc.exists) {
        if (this._isMounted) {
          this.setState({
            board: doc.data(),
            key: doc.id,
            isLoading: false
          }, () => {
            if (this.state.board.userImage !== undefined) {
              DataManager.saveToLocalStorage("backgroundPhoto", this.state.board.userImage)
            }
            this.setState({ firebaseData: doc.ref })

            //save fence data to local storage
            DataManager.saveFenceDataToLocalStorage(this.state.board.fenceData, doc.id)
          });
        }
      } else {
        this.addNewBoard()
      }

    });*/
   DataManager.getFirebaseData(this.state.boardid,  (doc) =>  {
    if (doc !== undefined) {
      if (this._isMounted) {
        this.setState({
          board: doc.data(),
          key: doc.id,
          isLoading: false
        }, () => {
          if (this.state.board.userImage !== undefined) {
            DataManager.saveToLocalStorage("backgroundPhoto", this.state.board.userImage)
          }
          this.setState({ firebaseData: doc.ref })

          //save fence data to local storage
          DataManager.saveFenceDataToLocalStorage(this.state.board.fenceData, doc.id)
        });
      }
    } else {
      this.addNewBoard()
    }
   }) 

  }

  addNewBoard() {
    const {
      title,
      description,
      author,
      date = new Date(),
      name,
      email,
      address,
      user_id,
      fenceData
    } = this.state;
    firebase.firestore().collection('boards').add({
      title,
      description,
      author,
      date,
      name,
      email,
      address,
      user_id,
      fenceData
    }).then((docRef) => {
      this.setState({
        boardid: docRef.id,
        title: '',
        description: '',
        author: '',
        date: new Date(),
        name: '',
        email: '',
        address: '',
        user_id: '',
        fenceData: ''
      });
      //this.props.history.push("/")
      DataManager.saveFenceDataToLocalStorage(fenceData, docRef.id)
      this.setState({ firebaseData: docRef })

    })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });

  }

  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      });
    return vars;
  }

  handleMainButton = buttonName => {
    alert("Main Button " + buttonName)
  }

  handleHeaderButton = buttonName => {
    alert("Main Button " + buttonName)
  }



  changeFenceTypeHandler = (dataFromChild) => {
    console.log("changeFenceTypeHandler " + dataFromChild);
    this.setState({ activePage: "changeFenceTypeHandler" })
  }

  changePlanHandler = (dataFromChild) => {
    console.log("changePlanHandler " + dataFromChild);
    this.setState({ fenceREady: true })
  }

  changeVisualizeHandler = (dataFromChild) => {
    console.log("changeVisualizeHandler " + dataFromChild);
    this.setState({ activePage: "changeVisualizeHandler" })
  }

  changeContactFormHandler = (dataFromChild) => {
    console.log("changeContactFormHandler APP " + dataFromChild);
    this.setState({ activePage: "changeContactFormHandler" })

  }

  changePartnerFinder = (dataFromChild) => {
    console.log("changePartnerFinder " + dataFromChild);
  }

  deleteProjectOnServer = (onServer) => {
    if (onServer) {
      firebase.firestore().collection('boards').doc(this.state.boardid).delete().then((doc) => {
        DataManager.deleteProject()
        this.reloadPage()
      })
    } else {
      DataManager.deleteProject()
      this.reloadPage()
    }

  }

  reloadPage = () => {
    var _url = window.location.href;
    _url = _url.substring(0, _url.lastIndexOf('/'));
    window.location.href = _url;
  }

  handleLoadProject = (load) => {
    this.setState({
      dialogLoadProjectOpen: false
    })

    if (load) {
      this.readAndProccessUrl()
    } else {
      this.loadLocalProjekt()
    }
  };





  render() {

    return (

      <div>

        <Router>

          <div className="App">
            <ThemeProvider theme={theme}>
              <Header clickHandler={this.handleHeaderButton} deleteProjectHandler={this.deleteProjectOnServer} updatedBoardId={this.state.boardid} fenceReady={this.state.fenceReady} />

              <Route exact path="/" render={() => <StartPage {...this.props} firebase={this.state.firebase} boardid={this.state.boardid} />} />
              <Route path="/choose" component={() => <Choose {...this.props} handleChangeFenceType={this.changeFenceTypeHandler} boardid={this.state.boardid} />} />
              <Route path="/plan" component={() => <PlanC handleChangePlan={this.changePlanHandler} boardid={this.state.boardid} />} />
              <Route path="/visualize" render={() => <Visualize handleChangeVisualize={this.changeVisualizeHandler} boardid={this.state.boardid} />} />
              <Route path="/contactForm" render={() => <ContactForm handleChangeContactForm={this.changeContactFormHandler} boardid={this.state.boardid} />} />
              <Route path="/partnerFinder" render={() => <PartnerFinder handlePartnerFinder={this.changePartnerFinder} boardid={this.state.boardid} />} />
              <Route path="/anleitung" render={() => <Anleitung />} />

            </ThemeProvider>
          </div>

        </Router>
        <Dialog

          open={this.state.dialogLoadProjectOpen}

          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Projekt laden</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Projekt
            </DialogContentText>
            Es wurde eine Projekt ID in der URL gefunden. Soll dieses Projekt geladen werden? Das aktuelle Projekt wird dadurch überschrieben!
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleLoadProject(false)} color="primary">
              Abbrechen
            </Button>
            <Button onClick={() => this.handleLoadProject(true)} color="primary" autoFocus>
              Laden
            </Button>
          </DialogActions>
        </Dialog>


      </div>

    );

  }
}

export default App;
