import React from 'react'
import { useDrag } from 'react-dnd'
import ItemTypes from './DragDropItemTypes'
import {DataManager} from '../DataManager'
const style = {
  
  position: 'relative',
  border: '0px dashed gray',
  backgroundColor: 'transparent',
  padding: '0px',
  cursor: 'move',
  display:'flex'
}
const DragDropBox = ({ id, left, top, width, hideSourceOnDrag, children, image, contrast }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, width, type: ItemTypes.BOX },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  if (isDragging && hideSourceOnDrag) {
    return <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1.0 }}/>
  }
  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.2 : 0.99, ...style, left, top, width:width, filter: 'contrast('+contrast+'%)'}}>
      {children}

    </div>
  )
}
export default DragDropBox

/*

      <img src={image} className="ImageDrag" alt="drag fence" style={{width:width / 3}}/>
      <img src={image} className="ImageDrag" alt="drag fence" style={{width:width / 3}}/>
      <img src={image} className="ImageDrag" alt="drag fence" style={{width:width / 3}}/>
*/