import React from "react";
import PropTypes from "prop-types";
import "./Button.css";

class Button extends React.Component {


  render() {
    const className = [
      "component-button"
    ];

    return (
      <div className={className.join(" ").trim()}>
        <button >{this.props.name}</button>
      </div>
    );
  }
}
Button.propTypes = {
  name: PropTypes.string,
  btnId:PropTypes.string,
  clickHandler: PropTypes.func,
};
export default Button;
