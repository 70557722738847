import React, { Component } from 'react'
import { app } from 'firebase';
import {DataManager} from '../DataManager'


//import {FenceApp} from './includes/js/fenceApp.js'


class fence extends Component {

    src = "./fence3D/fence.html?param1="+DataManager.getFromLocalStorage("path")

    constructor (props) {
        super(props)
        this.state = {
            random: 0,
            width: 0,
            height: 0,
            readOnly:false
       }
    
       this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
       
      }
componentDidMount(){
   // this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
}
updateWindowDimensions() {

var w =0

if(this.props.openWidth) {
w = 320
}

    var height = window.innerHeight - 50
    var width = "200"

    if(document.getElementById("ViewHeaderContainer") !== null) {
      height = height - document.getElementById("ViewHeaderContainer").clientHeight;
      width = window.innerWidth - w - 40
    }

    if(document.getElementById("VisualizeAppBar") !== null) {
      height = height - document.getElementById("VisualizeAppBar").clientHeight - 50;
    //  width = window.innerWidth - w - 40
    }
    
    console.log("window.innerWidth: "+width + " height: "+height )
    this.setState({ width: width , height: height });

     
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

getAlert() {
  this.updateWindowDimensions(); 
    this.setState({random: this.state.random + 1});
   
  }

    render() {      
        
   
        
        return (
       

        <iframe key={this.state.random} title="asd" className="Fence3DContainer" src={this.src+"&param2="+this.props.readOnly} style={{ height: this.state.height, width:this.state.width }}/>  

        )
    }
}

export default fence