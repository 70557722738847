import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles';
import { Carousel } from 'react-responsive-carousel';
import { DataManager } from './../../DataManager'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemText from '@material-ui/core/ListItemText';
var path = require('path');
const textureUrl = path.join(__dirname, './fence3D/assets/fence_data')


const styles = theme => ({
    root: {
    
      display:"flex",
      width:"100%",
      marginBottom:"25px",
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
    },
    VisualizeFenceList: {
     
    }
  });
  const StyledListItem = withStyles({
    root: {
      backgroundColor: "white",
      "&$selected": {
        backgroundColor: "#9fc01d"
      },
      "&:active": {
        backgroundColor: "#9fc01d"
      },
      "&$link": {
        backgroundColor: "#9fc01d"
      }
      ,
      "&:focused": {
        backgroundColor: "#9fc01d"
      }
    },
    selected: {}
  })(ListItem);
class ChooseS extends Component {
    fenceData
    selectedFences

    constructor(props) {
        super(props)

        this.state = {
            fenceSliderHeight: 220,
            componentContainerHeight:0,
            sliderValue: 1.5,
            items: Element,
            sliderItems: ["/assets/data/textures/101/20_20/element.png"],
            fenceCatType: 0,
            selectedFenceTypeIndex: 0,
            fencesForSelectedCategory: Element,
            thumbYPos: 0,
            fenceType: "",
            fenceTypeVariation: ""
        }

        // eslint-disable-next-line no-undef
      
        console.log("PROPS " + props.testid)
    }


    componentWillUnmount() {
        DataManager.updateLocalFenceData()


     }


    componentDidMount() {
 
  
       var _this = this
        var fenceCat = parseInt(DataManager.getFromLocalStorage('fenceCat'))
        var fenceType = DataManager.getFromLocalStorage('fenceType')
        var fenceHeight = DataManager.getFromLocalStorage('fenceHeight')
        var fenceTypeVariation = DataManager.getFromLocalStorage('fenceTypeVariation')

        this.setState({
            sliderValue: fenceHeight / 200
        })

        if (fenceCat === null || fenceCat === undefined) {
            fenceCat = 0
        }

        if (fenceType === null || fenceType === undefined) {
            fenceType = "0"
        }
            this.setState( {
                    fenceCatType: fenceCat,
                    fenceType: fenceType,
                    fenceTypeVariation:fenceTypeVariation
            }, () => {

                DataManager.loadFenceTypeTextures(function() {
                   _this.createSliderItems(false)
                   _this.props.onFenceChanged()
                })
    
            });
    }

    getIsSelected = (index) =>{

        if(this.state.fenceCatType === index) {
            return true
        }

        return false
    }


    handleChangeFenceType = (event, value) => {
     
        this.setState( {
            fenceCatType: value,
            selectedFenceTypeIndex:0,
            fenceType:0
            
        }, () => {
            DataManager.saveToLocalStorage('fenceCat', value.toString())
            
       
            this.createSliderItems(true)
        });

       
    };

    createItems(sliderItems) {

        return sliderItems.map(this.createItem);
    }

    createItem(item) {

        return <div id="carlouselItem" > < img src={item} /> </div>
    }


    createSliderItems(isInternal) {

        var obj = DataManager.getSliderItems(this.state.fenceCatType, this.state.fenceType, this.state.selectedFenceTypeIndex, this.state.fenceTypeVariation)

        console.log("this.state.selectedFenceTypeIndex: "+this.state.selectedFenceTypeIndex)
        if(!isInternal) {
                obj.sliderItems[obj.selectedFenceTypeIndex] = textureUrl + "/" + this.state.fenceType + "/" + this.state.fenceTypeVariation + "/element.png"
        } else {

        }

        this.setState({
            sliderItems: obj.sliderItems,
            fencesForSelectedCategory: obj.fencesFromCategory,
            selectedFenceTypeIndex: obj.selectedFenceTypeIndex,
            fenceType:obj.fencesFromCategory[obj.selectedFenceTypeIndex].folder._cdata,
        }, () => {
         


      
            DataManager.saveToLocalStorage('fenceType', obj.fencesFromCategory[obj.selectedFenceTypeIndex].folder._cdata)
            DataManager.saveToLocalStorage('fenceTypeVariation', this.state.fenceTypeVariation)
            DataManager.updateLocalFenceData()
            this.render()
        });
      
    }

    onCarouselChange = selectedIndex => {

          // this.updateWindowDimensions()
          if (this.state.fencesForSelectedCategory[selectedIndex] !== undefined) {
           
            console.log('onCarouselChange ' + selectedIndex);
            var fenceType = this.state.fencesForSelectedCategory[selectedIndex].folder._cdata
           
  
              var variation = DataManager.getVariation(selectedIndex, this.state.fencesForSelectedCategory)
              DataManager.saveToLocalStorage('fenceTypeVariation', variation)
              this.setState({
                fenceTypeVariation:variation
               })

     
       
    
            DataManager.saveToLocalStorage('fenceType', fenceType)
            this.setState({
                fenceType:fenceType, 
                selectedFenceTypeIndex:selectedIndex
               }, () => {

                DataManager.updateLocalFenceData()
                this.createSliderItems()
               
                this.props.onFenceChanged()
            });

        } 




    }





    variationIndex = 0
    
    onCarouselItemClick = (selectedIndex) => {
        // this.updateWindowDimensions()
        if (this.state.fencesForSelectedCategory[selectedIndex] !== undefined) {
          
                 
            let variationCount = this.state.fencesForSelectedCategory[selectedIndex].variation.length

            if( this.variationIndex < variationCount - 1) {
                this.variationIndex = this.variationIndex + 1
            } else {
                this.variationIndex = 0
            }
           
            var variation = DataManager.getVariation(selectedIndex, this.state.fencesForSelectedCategory, this.variationIndex)
            DataManager.saveToLocalStorage('fenceTypeVariation', variation)
            let loadedFenceType = DataManager.getFromLocalStorage('fenceType')
            console.log("SELECTED INDEX "+selectedIndex+" loadedFenceType: "+loadedFenceType)
            let newSliderItems = this.state.sliderItems
            newSliderItems[selectedIndex] = textureUrl + "/"+loadedFenceType+"/" + variation + "/element.png"
            this.setState({
                sliderItems: newSliderItems
               })

            
           // this.createSliderItems()
            DataManager.updateLocalFenceData()
           this.props.onFenceChanged()
        }


    }
    getZauntypName = () => {
      if (this.state.fenceType.length > 0) {
             return  DataManager.getFenceTypeData(this.state.fenceType)[0].Zauntyp
          } else {
              return ""
          }
      }


    render() {

        const { classes } = this.props;

        const ShowFences = (itemsData) => {

            return (
          

                    <Carousel selectedItem={this.state.selectedFenceTypeIndex} onClickItem={(index, selectedItem) => this.onCarouselItemClick(index)} onChange={(index) => this.onCarouselChange(index)} showThumbs={false} className="VisualizeFenceCarousel" dynamicHeight={false} useKeyboardArrows={true} emulateTouch={true}>
                        {this.createItems(this.state.sliderItems)}
                    </Carousel>
          
       
            )
        }

        return (
            <div  className={classes.root} >
           
    <div className="VisualizeFenceListContainer">
                <List component="nav" aria-label="Zaun Varianten" className="VisualizeFenceList" >
        <StyledListItem
          button
          selected={this.state.fenceCatType === 0}
          onClick={event => this.handleChangeFenceType(event, 0)}
        >
      
          <ListItemText primary="Heim & Garten" />
        </StyledListItem>
        <StyledListItem
          button
          selected={this.state.fenceCatType === 1}
          onClick={event => this.handleChangeFenceType(event, 1)}
        >
      
          <ListItemText primary="Sicht-/Lärmschutz" />
        </StyledListItem>
  
        <StyledListItem
          button
          selected={this.state.fenceCatType === 2}
          onClick={event => this.handleChangeFenceType(event, 2)}
        >
          <ListItemText primary="mehr Sicherheit" />
        </StyledListItem>
        <StyledListItem
          button
          selected={this.state.fenceCatType === 3}
          onClick={event => this.handleChangeFenceType(event, 3)}
        >
          <ListItemText primary="Tierhaltung" />
        </StyledListItem>
        </List>
   
</div>  <Tooltip title="Klick für Farbwechsel" placement="bottom" enterDelay='200'>
                <div  className="VisualizeFenceCarouselContainer">
                
                {this.getZauntypName()}<br></br><br></br>
              
                        <ShowFences> </ShowFences>
                      
                </div>
                </Tooltip>
            </div>
          
        )
    }
}

export default withStyles(styles)(ChooseS);