import React from 'react'
import PropTypes from "prop-types";
import Button from './Button';


import { BrowserRouter as Router, Link, withRouter} from "react-router-dom";



class StartPage extends React.Component {


  constructor(props) {
    super(props)

    this.state = {
        firebase:props.firebase,

    }


}

  componentWillMount() {
    // will trigger the callback function whenever a new Route renders a component(as long as this component stays mounted as routes change)
   

  
  }

  componentDidMount(){
    //this.ref.doSignInAnonymously()

   // const firebase = React.useContext(FirebaseContext) 
 //  firebase.doSignInAnonymously()
  }


  render() {

    return (
      <div className="StartPageMainContainer">
        <div className="StartPageButtonGroup">
          <Link to="Choose"> <Button name="Zaun planen" btnId="btnPlanen">Zaun planen</Button></Link>
          <br></br>
          <Link to="Anleitung"> <Button name="Anleitung" btnId="btnAnleitung">Anleitung</Button></Link>
          <br></br>
          <Link to="PartnerFinder"> <Button name="Kontakt Zaunteam" btnId="btnKontakt" >Kontakt Zaunteam</Button></Link>
      



        </div>
      </div>
    )
  }
}
StartPage.propTypes = {
  clickHandler: PropTypes.func,
};
export default StartPage