import React, { Component } from 'react';
import MapDetails from './ui/MapDetails'
import Fence3D from '../fence3D/fence'
import { DataManager } from '../DataManager'
import zaunteam_logo_de from '../media/zaunteam_logo_de.png'
import  pdfMake  from 'pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";



var dd = {
  content: [
    'First paragraph',
    'Another paragraph, this time a little bit longer to make sure, this line will be divided into at least two lines'
  ]
  
}


class DetailOverView extends Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          height: 300,
          width:300,
          photoBackground: null,
          selectedPhoto: null,
          displayPhoto: false,
          photoStyle: 'contain',
    
        };
    
      }

      componentDidMount() {
      

      
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        var pdf = pdfMake.createPdf(dd).download();
 
 
        var photo = DataManager.getFromLocalStorage("backgroundPhoto")
    
        if (photo != null) {
          this.setState({ selectedPhoto: photo, photoBackground: photo, displayPhoto: true });
        }

    
      }


    
  

  render(props) {
    var divStyle = {
        backgroundImage: 'url(' + this.state.photoBackground + ')',
        backgroundPosition: 'center',
        backgroundSize: this.state.photoStyle,
        rotation: '0',
        backgroundRepeat: 'no-repeat'
  
        // backgroundImage:'url(/fence3D/assets/images/bgTest.jpg)'
      }

    return (

      
      <div className="OverViewContainer">
            <img id='imgLogo' src={zaunteam_logo_de}></img>

    <div className="OverViewContainerText">
      <div>Anwendung: {DataManager.getFenceCatName()}</div>
      <div>Zaunlange: {DataManager.getFenceLength()}m</div>
      <div>Zaunhöhe: {DataManager.getFenceHeight()}m</div>
<hr></hr>
    </div>
    <div className="OverViewContainerComponents">
      <div className="OverViewContainerMapDetails">
            <MapDetails/>
      </div>
      <div className="OverViewContainerFEnce3D">
         
          <iframe src="./fence3D/fence.html?param2=true"/> 
         
      </div>

        </div>
</div>

    );
  }
}

export default DetailOverView