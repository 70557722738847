import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import GoogleMapReact from "google-map-react";

import AppBar from "@material-ui/core/AppBar";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { DataManager } from '../../DataManager'
import EditIcon from "@material-ui/icons/Edit";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import UndoIcon from '@material-ui/icons/Undo';
import LocationSearch from './LocationSearch'
import EditDoor from '@material-ui/icons/CropDin';
import InfoIcon from '@material-ui/icons/Info';
import MyLocationIcon from '@material-ui/icons/MyLocation';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';


const drawerWidth = 240;
const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    position: 'relative',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    top: 135,
    flexGrow: 1,
    flexShrink: 100,
    flexBasis: 0,
    height: '100%',
    width: drawerWidth,
    display: 'flex',

    zIndex: 1200,
    outline: 'none',
    position: 'fixed',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 100,
    whiteSpace: 'nowrap',

  },
  selected: {
    selectedBackground: 'blue'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing.unit * 3,

  },
  checkbox: {
  },
  checkboxDoor: {

  }
});

const StyledListItem = withStyles({
  root: {
    backgroundColor: "white",
    "&$selected": {
      backgroundColor: "#9fc01d"
    },
    "&:hover": {
      backgroundColor: "#9fc01d"
    },
    "&:focus": {
      backgroundColor: "#9fc01d"
    }
  },
  selected: {}
})(ListItem);

class PlanC extends Component {

  escFunction = this.escFunction.bind(this);
  loadedMaps = Object();
  loadedMap = Object();
  drawingManager;
  //polylines = [];
  markers = [];
  distanceMarkers = []
  doorMarkers = []

  doorMarkerObject = Object();
  cameraViewPath = Object();
  viewerPosition = null;
  centerMarker = Object();
  viewerMarker = Object();

  viewerOffsetAngle = 0
  cameraDistMarkerPos = null
  showInfos = false

  constructor(props) {
    super(props);
    this.state = {
      boardid:props.boardid,
      open: false,
      width: 0,
      height: 0,
      tabValue: 0,
      polyline: [],
      loadedMap: null,
      dialogOpen: false,
      dialogDoorOpen: false,
      dialogDoorDescription: false,
      dialogPhotoOpen: false,
      dialogLocationSearchOpen: false,
      toolDescription: '',
      toolDetailDescription: '',
      isDragingPost: false,
      showInfos: false,
      showDoorMarkers: false, 
      locationIsSet: false,
      mapDrawingColor:"#FFFFFF"

    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleChangeTool = this.handleChangeTool.bind(this);
    this.getDoorCheckboxLabel = this.getDoorCheckboxLabel.bind(this)
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.setState({
        toolDetailDescription: "",
        isDragingPost: false
      })


      this.loadedMaps.event.removeListener(this.move);
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    document.addEventListener("keydown", this.escFunction, false);
    this.displayDescription("")

  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.removeEventListener("keydown", this.escFunction, false);
  }

  updateWindowDimensions() {
    const height =
      window.innerHeight -
      document.getElementById("ViewHeaderContainer").clientHeight -
      50 - 50;
    this.setState({ width: window.innerWidth, height: height });
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleChangePlan = buttonId => {
    this.props.handleChangePlan(buttonId);
  };

  displayDescription = (text = "", detailText = "", isDragingPost = false) => {

    if (text.length === 0) {
      text = this.state.toolDescription
      detailText = ""
      if (this.state.tabValue === 1) {
        text = "Mit der Maus auf einen Eckpunkt klicken um den Zaunpfosten zu verschieben"
      }
      if (this.state.tabValue === 0) {
        text = "Mit der Maus auf einen Eckpunkt klicken und die Maus bewegen um einen neuen Zaunpfosten zu erstellen"
      }
      if (this.state.tabValue === 2) {
        text = "Mit der Maus auf den Pfeil klicken der den Betrachter markeriert."
      }

      if (this.state.tabValue === 3) {
        text = " Mit der Maus auf den Zaun klicken um ein Tor hinzuzufügen oder zu verschieben"
      }

      if (!this.checkPolylineHasContent()) {
        text = "Mit der Maus auf einen punkt auf der Karte klicken um einen Pfosten zu setzen."
        detailText = "Mit doppelklick oder esc beenden"
      }
    }

    this.setState({
      toolDescription: text,
      toolDetailDescription: detailText,
      isDragingPost: isDragingPost
    })
  }

  handleChangeTool = (newValue) => {
    //setValue(newValue);
    console.log("CHANGE " + newValue);
    this.setState(function (state, props) {
      return {
        tabValue: newValue
      };
    });

    if (!this.checkPolylineHasContent()) {

      if (newValue === 5) {
        this.handleStartSearchLocationDialog()
      } else {
        this.updateDrawingManager(
          this.loadedMaps.drawing.OverlayType.POLYLINE,
          0
        );
      }

    } else {

      this.state.polyline.setEditable(false)

      switch (newValue) {
        case 0:
          if (this.checkPolylineHasContent()) {
            this.state.polyline.setEditable(true)
          }


          this.updateDrawingManager(
            this.loadedMaps.drawing.OverlayType.EDIT,
            newValue
          );

          break;
        case 1:
          if (this.checkPolylineHasContent()) {
            this.state.polyline.setEditable(true)
          }


          this.updateDrawingManager(
            this.loadedMaps.drawing.OverlayType.EDIT,
            newValue
          );

          break;
        case 2:
          this.updateViewerPosition()
          this.updateDrawingManager(
            this.loadedMaps.drawing.OverlayType.EDIT,
            newValue
          );
          this.displayDescription("")
          break;
        case 3:
          // this.updateDrawingManager(null);
          // this.updateDoorMarkers()
          this.displayDescription("")
          break;
        case 4:
          var zuio = this.state.polyline;

          zuio.getPath().j.pop();

          this.savePolylinePath(zuio);
          zuio.getPath().removeAt(zuio.getPath().getLength() - 1);

          break;
        case 5:
          this.handleStartSearchLocationDialog()

          break;
        case 6:
          this.handleClickOpen()
          break;
        case 8:
          this.toggleInfos()

          break;
        default:
      }

      this.setViewerMarker()

    }
    setTimeout(this.displayDescription, 50)
  };

  toggleInfos = () => {
  
    var tog = false
    if (!this.state.showInfos) {
      tog = true
    }

    this.setState({ showInfos: tog }, () => {
      if (this.checkPolylineHasContent()) {
        this.updateDrawings()
      }
    })
  };

  removePolylines = () => {
    //  this.state.polyline.map(this.removeItem);

    this.setState({ polyline: [] })


  };

  removeItem = item => {
    item.setMap(null);
  };

  createMapOptions = {
    panControl: true,
    mapTypeControl: true,
    scrollwheel: true,
    scaleControl: true,
    streetViewControl: true,
    tiltControl:false,
    mapTypeId:'satellite',
    tilt:0,
    rotateControl: false,
    styles: [
      {
        stylers: [
          { saturation: -60 },
          { gamma: 1 },
          { lightness: 1 },
          { visibility: "on" }
        ]
      }
    ]
  };

  onGoogleMapsLoaded = (map, maps) => {

    // === A method which returns the length of a path in metres ===
maps.Polyline.prototype.Distance = function() {
  var dist = 0;
  for (var i=1; i < this.getPath().getLength(); i++) {
    dist += this.getPath().getAt(i).distanceFrom(this.getPath().getAt(i-1));
  }
  return dist;
}

// === A method which returns the bounds as a GLatLngBounds ===
maps.Polyline.prototype.Bounds = function() {
  var bounds = new maps.LatLngBounds();
  for (var i=0; i < this.getPath().getLength(); i++) {
    bounds.extend(this.getPath().getAt(i));
  }
  return bounds;
}

    maps.LatLng.prototype.distanceFrom = function(newLatLng) {
      var EarthRadiusMeters = 6378137.0; // meters
      var lat1 = this.lat();
      var lon1 = this.lng();
      var lat2 = newLatLng.lat();
      var lon2 = newLatLng.lng();
      var dLat = (lat2-lat1) * Math.PI / 180;
      var dLon = (lon2-lon1) * Math.PI / 180;
      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(lat1 * Math.PI / 180 ) * Math.cos(lat2 * Math.PI / 180 ) *
        Math.sin(dLon/2) * Math.sin(dLon/2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = EarthRadiusMeters * c;
      return d;
    }

    maps.Polyline.prototype.GetPointAtDistance = function(metres) {
      // some awkward special cases
      if (metres == 0) return this.getPath().getAt(0);
      if (metres < 0) return null;
      if (this.getPath().getLength() < 2) return null;
      var dist=0;
      var olddist=0;
      for (var i=1; (i < this.getPath().getLength() && dist < metres); i++) {
        olddist = dist;
        dist += this.getPath().getAt(i).distanceFrom(this.getPath().getAt(i-1));
      }
      if (dist < metres) {
        return null;
      }
      var p1= this.getPath().getAt(i-2);
      var p2= this.getPath().getAt(i-1);
      var m = (metres-olddist)/(dist-olddist);
      return new maps.LatLng( p1.lat() + (p2.lat()-p1.lat())*m, p1.lng() + (p2.lng()-p1.lng())*m);
    }

    maps.Polyline.prototype.getBounds = function () {
      var bounds = new maps.LatLngBounds();
      this.getPath().forEach(function (item, index) {
        bounds.extend(new maps.LatLng(item.lat(), item.lng()));
      });
      return bounds;
    };
    map.setTilt(0)
    this.loadedMaps = maps;
    this.loadedMap = map;

    this.initDrawingManager()
    this.drawExistingPolyline()
    if (this.checkPolylineHasContent()) {
      this.updateDrawings()

    } else {
      this.initNewProjectMap()
     
      if(DataManager.getFromLocalStorage("locationSet")) {
        this.updateViewerPosition()
   
      } else {
            this.handleStartSearchLocationDialog()
      }
      
    }

    maps.event.addListener(map, "maptypeid_changed", () =>  {
      var newMapType = map.getMapTypeId();
      var newColor = '#FFFFFF'
     
      if(newMapType === 'roadmap' ) {
        newColor = '#000000'
      }
      

      this.setState({
        mapDrawingColor:newColor
      })
      let polyline = this.state.polyline

      polyline.setOptions({strokeColor:newColor});

      this.updateDrawings()

  });

  };

  initNewProjectMap = () => {
    console.log("initNewProjectMap")
    this.displayDescription()
  }

  updateDrawings = () => {
    if (this.checkPolylineHasContent()) {
      console.log("this.state.polyline.getBounds() " + this.state.polyline.length)
      
      this.updateViewerPosition()
      this.drawFenceCenter(this.state.polyline.getBounds())
      this.updatePolylineDistanceMarkers()
      this.loadDoorMarkers()
    }
  }

  initDrawingManager = () => {
    var _this = this;
    this.drawingManager = new this.loadedMaps.drawing.DrawingManager({
      drawingMode: this.loadedMaps.drawing.OverlayType.POLYLINE,
      drawingControl: false,
      drawingControlOptions: {
        position: this.loadedMaps.ControlPosition.BOTTOM_CENTER,
        drawingModes: ["polyline"]
      },
      circleOptions: { radius: "80px" },
      polylineOptions: {
        //   fillColor: "#ffff00",

        fillOpacity: 1,
        strokeWeight: 5,
        clickable: true,
        editable: true,
        draggable: true,
        zIndex: 1,
        circleOptions: { radius: 80 }
      }
    });

    this.loadedMaps.event.addListener(this.drawingManager, "polylinecomplete", function (
      polyline
    ) {
      console.log("polylinecomplete")

      _this.savePolylinePath(polyline);
      _this.polylineEvents(polyline);
      _this.handleChangeTool(1)

    });

    this.loadedMaps.event.addListener(this.drawingManager, "overlaycomplete", function (
      overlay
    ) {
      console.log("overlaycomplete " + overlay);
    });

    this.drawingManager.setMap(this.loadedMap);
  }

  repositionDoorMarker = (doorMarkerObjectInternal, latLng = null) => {

    let _this = this
    var firstPostIndex = 0
    var position = latLng
    var heading = 0

    if (doorMarkerObjectInternal != null) {
      firstPostIndex = doorMarkerObjectInternal.firstPostIndex
    }

    this.removeAllDoorMarkers()
    if (latLng == null) {


      if (firstPostIndex === undefined) {
        doorMarkerObjectInternal = { firstPostIndex: 0, firstPostDistancePercent: 0.5 }
        firstPostIndex = 0
      }
      var distanceToFirstPostPercent = doorMarkerObjectInternal.firstPostDistancePercent

      var latFirstPost = this.state.polyline.getPath().getArray()[firstPostIndex]
      var latSecondPost = this.state.polyline.getPath().getArray()[firstPostIndex + 1]

      if (latSecondPost === undefined) {
        latFirstPost = this.state.polyline.getPath().getArray()[firstPostIndex - 1]
        latSecondPost = this.state.polyline.getPath().getArray()[firstPostIndex]
      }


 
      var distanceToFirstPost = this.getFenceLength(this.state.polyline) * distanceToFirstPostPercent

     
      position = this.state.polyline.GetPointAtDistance(this.getFenceLength(this.state.polyline)*(distanceToFirstPostPercent))


      let pos1 = _this.findClosest(position, _this.getFineData(_this.state.polyline.getPath().getArray(), 0.5))
      let pos2 = _this.findClosest(position, _this.getFineData(_this.state.polyline.getPath().getArray(), 0.1))
      heading = _this.loadedMaps.geometry.spherical.computeHeading(pos1, pos2);

      console.log("distanceToFirstPost "+distanceToFirstPost)

    } else {



      let pos1 = _this.findClosest(latLng, _this.getFineData(_this.state.polyline.getPath().getArray(), 0.5))
      let pos2 = _this.findClosest(latLng, _this.getFineData(_this.state.polyline.getPath().getArray(), 0.1))

      heading = _this.loadedMaps.geometry.spherical.computeHeading(pos1, pos2);

    }

    let doorMarker = new _this.loadedMaps.Marker({
      position: position,
      map: _this.loadedMap,
      visible: this.state.showDoorMarkers,
      index: firstPostIndex,
      icon: {
        path: "M20 6.54v10.91c-2.6-.77-5.28-1.16-8-1.16-2.72 0-5.4.39-8 1.16V6.54c2.6.77 5.28 1.16 8 1.16 2.72.01 5.4-.38 8-1.16M21.43 4c-.1 0-.2.02-.31.06C18.18 5.16 15.09 5.7 12 5.7c-3.09 0-6.18-.55-9.12-1.64-.11-.04-.22-.06-.31-.06-.34 0-.57.23-.57.63v14.75c0 .39.23.62.57.62.1 0 .2-.02.31-.06 2.94-1.1 6.03-1.64 9.12-1.64 3.09 0 6.18.55 9.12 1.64.11.04.21.06.31.06.33 0 .57-.23.57-.63V4.63c0-.4-.24-.63-.57-.63z",
        scale: 2,
        strokeColor:this.state.mapDrawingColor,
        rotation: heading - 90,
        anchor: new _this.loadedMaps.Point(10, 12),
        labelOrigin: new _this.loadedMaps.Point(0, -5),
      },
      label: {
        text: "1.5 m",
        color: this.state.mapDrawingColor,
      },
      draggable: true

    });


    _this.doorMarkers.push(doorMarker)

    _this.snapDoorToFence(position, doorMarker)

    doorMarker.setMap(_this.loadedMap)

    this.doorMarkerObject = doorMarkerObjectInternal

    _this.loadedMaps.event.addDomListener(doorMarker, 'dragend', function (e) {
      /*  let pos = _this.findClosest(e.latLng, _this.getFineData(_this.state.polyline.getPath().getArray(), 0.5))
        doorMarker.setPosition(pos);*/
      // _this.snapDoorToFence(e.latLng, doorMarker)
      _this.addNewDoorMarker(e.latLng)
      _this.clickDoorMarker()

    });

    _this.loadedMaps.event.addDomListener(doorMarker, 'drag', function (e) {
      //doorMarker.setPosition(find_closest_point_on_path(e.latLng,padded_points));
      console.log("DRAG DOOR MARKER")
      /*let pos = _this.findClosest(e.latLng, _this.getFineData(_this.state.polyline.getPath().getArray(), 0.5))
      doorMarker.setPosition(pos);*/
      _this.snapDoorToFence(e.latLng, doorMarker)

    });

    doorMarker.addListener('click', this.clickDoorMarker);

    //  });

  }


  loadDoorMarkers = () => {

    this.removeAllDoorMarkers()
    var fenceData = DataManager.getFenceDataFromLocalStorage()
    let doors = fenceData.coordinates.doors
    if (doors[0] !== null && doors[0] !== undefined) {


      this.doorMarkerObjects = doors

      let doorMarkerObjectInternal = this.doorMarkerObjects[0]

      if (doorMarkerObjectInternal !== null && doorMarkerObjectInternal !== undefined) {

        this.repositionDoorMarker(doorMarkerObjectInternal)
      }
    } else if (this.state.tabValue === 3) {
      this.setState({ dialogDoorDescription: true })
    }
  }

   getIndexOfNearestPoint(path, hoveredPoint) {
    let index = -1;
    let minDistance = 1000;
  
    path.forEach((pointOnPath, idx) => {
      const dist = this.loadedMaps.geometry.spherical.computeDistanceBetween(
        hoveredPoint,
        pointOnPath
      );
  
      if (dist < minDistance){
        minDistance = dist;
        index = idx;
      }
    });
  
    return index;
  }
  getDistanceAlongPath(path, startIndex, endIndex) {
    let distance = 0;
    for (let ii = startIndex; ii < endIndex; ii++) {
      distance += this.loadedMaps.geometry.spherical.computeDistanceBetween(
        path[ii],
        path[ii + 1]
      );
    }
    return distance;
  }
  addNewDoorMarker = (latLng, polyline = null) => {
    var doorMarkerObjectInternal = { firstPostIndex: 0, firstPostDistancePercent: 0.5 }
    var fineData = this.getFineData(this.state.polyline.getPath().getArray(), 0.1)
    if (polyline != null) {
      console.log("POLYLINE " + this.state.polyline)
    }

    if (latLng != null) {

      var adjustedLatlng = this.findClosest(latLng, fineData)


       var firstPost = this.state.polyline.getPath().getArray()[0]
      var distanceFirstToMarker = this.loadedMaps.geometry.spherical.computeDistanceBetween(firstPost, adjustedLatlng)
       
       var totalLength = this.getFenceLength(this.state.polyline)
   

      const startIndex = 0;

      const endIndex = this.getIndexOfNearestPoint(fineData, adjustedLatlng);
      const distance = this.getDistanceAlongPath(fineData, startIndex, endIndex);

      var distanceToFirstPostPercent = distance / totalLength
      console.log("distanceFirstToMarker " + distanceFirstToMarker + " totalLength: " + totalLength + " distanceToFirstPostPercent: " + distanceToFirstPostPercent)
      doorMarkerObjectInternal = { firstPostIndex: 0, firstPostDistancePercent: distanceToFirstPostPercent }
    }

    this.repositionDoorMarker(doorMarkerObjectInternal, adjustedLatlng)

  }



  getDoorCheckboxLabel = () => {

    if (this.doorMarkers.length > 0) {
      return "Tor anzeigen"
    }
    return "Tor hinzufügen"

  }

  toggleDoorMarkers = () => {

    var show = !this.state.showDoorMarkers


    this.setState({
      showDoorMarkers: show
    }, () => {
      if (this.checkPolylineHasContent()) {

        if (this.doorMarkers.length !== 0) {

          var doorMarker = this.doorMarkers[this.doorMarkers.length - 1]
          doorMarker.setVisible(this.state.showDoorMarkers)
        } else if (this.state.showDoorMarkers) {
          this.handleChangeTool(3)
        }

      }
    })
  }

  snapDoorToFence = (latLng, marker = null) => {
    let pos = this.findClosest(latLng, this.getFineData(this.state.polyline.getPath().getArray(), 0.1))
    console.log("POS: " + pos)
    var doorMarker = this.doorMarkers[this.doorMarkers.length - 1]
    if (marker != null) {
      doorMarker = marker
    }
    doorMarker.setPosition(pos);

  }

  handleRemoveDoorDialog = () => {
    this.removeAllDoorMarkers()
    this.setState({ dialogDoorOpen: false });
    this.setState({ tabValue: 1 });
    this.toggleDoorMarkers()
  };

  removeAllDoorMarkers = () => {
    this.doorMarkers.forEach(item => {
      if (item !== null && item !== undefined) {
        try {
          item.setMap(null)
        }
        catch  { // nicht Standard

        }
      }
    })

    this.doorMarkers = []
  }

  handleSaveDoorDialog = () => {
    this.setState({ dialogDoorOpen: false });
    this.saveDoors()

  };

  handleContinueDoorDialog = () => {
    this.setState({ dialogDoorOpen: false });
    this.saveDoors()

  };

  handleDialogDoorDescriptionClose = () => {


    this.setState({ dialogDoorDescription: false }, () => {

      if (this.checkPolylineHasContent()) {
        var firstPost = this.state.polyline.getPath().getArray()[0]
        var secondPost = this.state.polyline.getPath().getArray()[1]
        if (firstPost != null && secondPost != null) {
         
          this.addNewDoorMarker(null)
        }

      }
    })

  };

  saveDoors = () => {

    if (this.doorMarkers.length > 0) {
      var fenceData = DataManager.getFenceDataFromLocalStorage()
      console.log("DROP DOOR MARKER " + this.doorMarkers[0])


      fenceData.coordinates.doors = [this.doorMarkerObject]
      DataManager.updateFenceDataSimple(fenceData)

    }

  }

  clickDoorMarker = (e) => {
    this.setState({ dialogDoorOpen: true });

  }

  getFineData = (roughData, resolution) => {
    var fineData = [];
    var latLngA;
    var latLngB;
    var steps;
    var step;
    for (var i = 1; i < roughData.length; i++) {
      latLngA = roughData[i - 1];
      latLngB = roughData[i];
      var distanceDiff = this.loadedMaps.geometry.spherical.computeDistanceBetween(latLngA, latLngB);
      steps = Math.ceil(distanceDiff / resolution);
      step = 1 / steps;

      for (var j = 0; j < steps; j++) {
        var interpolated = this.loadedMaps.geometry.spherical.interpolate(latLngA, latLngB, step * j);
        fineData.push(interpolated)
      }
      console.log("fineData.length STEP:" + step + " fineData.length STEPs:" + steps + " fineData.length RESOLUTION: " + resolution)
    }
    console.log("fineData.length:" + fineData.length)
    return fineData;
  }

  findClosest = (llng, listData) => {
    var arr = listData;
    var pnt = llng;
    var distArr = [];
    var dist = this.loadedMaps.geometry.spherical.computeDistanceBetween;


    for (var index in arr)
      distArr.push([arr[index], dist(pnt, arr[index])]);

    return distArr.sort(function (a, b) {
      return a[1] - b[1];
    })[0][0];
  }

  // end door marker

  updatePolylineDistanceMarkers = () => {

    var _this = this;
    _this.distanceMarkers.forEach(marker => {
      marker.setMap(null)
    })
    _this.distanceMarkers = []

    //this.state.polyline.getPath().forEach(polyline => {

    var currentPost = null
    _this.state.polyline.getPath().forEach(e => {//can't do polyline.getPath()[i] because it's a MVCArray

      let nextPost = e

      if (currentPost != null) {

        let inBetween = _this.loadedMaps.geometry.spherical.interpolate(currentPost, nextPost, 0.50);
        let distance = _this.loadedMaps.geometry.spherical.computeDistanceBetween(currentPost, nextPost);
        let distanceMarker = new _this.loadedMaps.Marker({
          position: inBetween,
          map: _this.loadedMap,
          visible: this.state.showInfos,
          icon: {
            url: "data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAtCAYAAABYtc7wAAAAfElEQVR4nO3RQQHAQAwEobT+Pe/Z4DFY4Nu2C+OvwlIIphBMIZhCMIVgCsEUgikEUwimEEwhmEIwhWAKwRSCKQRTCKYQTCGYQjCFYArBFIIpBFMIphBMIZhCMIVgCsEUgikEUwimEEwhmEIwhWAKwRSCKQRTCKYQTCGSu3uLtwRWzSIaywAAAABJRU5ErkJggg==",
            scale: 1,
            size: new _this.loadedMaps.Size(100, 45),
            scaledSize: new _this.loadedMaps.Size(80, 25),
            anchor: new _this.loadedMaps.Point(0, 0),
            labelOrigin: new _this.loadedMaps.Point(38, 14),

          },
          label: {
            text: distance.toFixed(2) + " m",
            color: 'black',

          },

        });
        _this.distanceMarkers.push(distanceMarker)
        distanceMarker.setMap(_this.loadedMap)
      }


      currentPost = nextPost
      console.log("CENTER " + e)
    })
    //});
  }

  drawExistingPolyline = () => {
    var path = DataManager.getMapFenceDataFromLocalStorage()


    if (path != null && path.length > 0) {
      this.updateDrawingManager(
        this.loadedMaps.drawing.OverlayType.EDIT,
        1
      );
      var savedPolyline = new this.loadedMaps.Polyline({
        path: path,
        geodesic: true,
        strokeOpacity: 0.9,
        strokeWeight: 5,
        zIndex: 1,
        draggable: true,
        editable: true

      });

 
      this.setState({ polyline: savedPolyline });

      //  this.state.polyline.push(savedPolyline);

      this.polylineEvents(savedPolyline);

 
      savedPolyline.setOptions({strokeColor:this.state.mapDrawingColor});
      this.state.polyline.setMap(this.loadedMap);
      this.setBoundsToViewAll()
    }

  }

    // VIEWER POSITION START
    updateViewerPosition = (newViewerPosition) => {
   setTimeout(this.drawViewerPath, 40)
    setTimeout(this.setViewerPosition(newViewerPosition), 20)
    setTimeout(this.setViewerMarker, 60)
  }

  setViewerPosition = (newViewerPosition) => {

    if (this.viewerOffsetAngle === 360) {
      this.viewerOffsetAngle = 0
    }
    this.viewerOffsetAngle = (this.viewerOffsetAngle + 90)
    //this.cameraDistMarkerPos = this.loadedMaps.geometry.spherical.computeOffset(bounds.getCenter(), 10, this.viewerOffsetAngle)

    if (newViewerPosition == null || newViewerPosition === undefined) {

      let fenceData = DataManager.getFenceDataFromLocalStorage()

      if (fenceData !== undefined && fenceData.config !== undefined) {
        var cameraConfig = fenceData.config.camera
        

        var distance = cameraConfig.radius
        var center = this.getFenceCenter()

        console.log("CENTERERERER "+center)

        var angle = (cameraConfig.rotation * -1) - 90
        var rotation = angle

        if (center === undefined) {
          center = this.loadedMap.getCenter()
        }

        if (this.loadedMaps.geometry !== undefined) {

          if (cameraConfig.coordinates === undefined || cameraConfig.coordinates == null) {
            newViewerPosition = this.loadedMaps.geometry.spherical.computeOffset(center,
              distance,
              rotation)

            // this.setState({dialogLocationSearchOpen:true})

          } else {
            //this.setState({locationIsSet:true})
            newViewerPosition = new this.loadedMaps.LatLng(cameraConfig.coordinates.lat, cameraConfig.coordinates.lng)
          }


        } else {

          console.log("this.loadedMaps.geometry UNDEFINED ")
          newViewerPosition = this.loadedMaps.geometry.spherical.computeOffset(center,
            50,
            0)
        }


      } else {
        console.log("fenceData undefined or null " + fenceData)


      }
    } else {
      console.log("fenceData exists " + newViewerPosition)
    }
    this.viewerPosition = newViewerPosition

  }


  setViewerMarker = () => {
    let _this = this
    console.log("setViewerMarker ")
    if (this.viewerMarker.map != null) {


      this.viewerMarker.setMap(null)
      this.viewerMarker.position = this.viewerPosition

      this.viewerMarker.icon.rotation = this.cameraHeading

      this.viewerMarker.setMap(this.loadedMap)
      // 


    } else {


      var icon = {
        path: "M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z",
        // path: "M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6 1.41-1.41zM6 6h2v12H6V6z", // url
        // url:MarkerDirectionCameraRotate,
        scale: 2,
        strokeColor: this.state.mapDrawingColor,
       
        //scaledSize: new this.loadedMaps.Size(20, 20), // scaled size
        labelOrigin: new this.loadedMaps.Point(33, 33), // origin
        anchor: new this.loadedMaps.Point(12, 12),
        rotation: this.cameraHeading
      };

      this.viewerMarker = new this.loadedMaps.Marker({
        position: _this.viewerPosition,
        draggable: true,
        icon: icon

      });
      this.viewerMarker.addListener('click', this.clickMarker);

      this.viewerMarker.addListener('dragend', this.dropMarker);
      this.viewerMarker.addListener('dragstart', this.dragStartMarker);
      this.viewerMarker.addListener('mouseover', this.viewerMarkerOver);
      this.viewerMarker.addListener('mouseout', this.viewerMarkerOut);

      this.viewerMarker.setMap(this.loadedMap)
    }


    if (this.viewerMarker.map != null) {


      this.viewerMarker.setDraggable(true);
      this.viewerMarker.icon.strokeColor = this.state.mapDrawingColor

    }
  /*  let defaultViewerPosition = new this.loadedMaps.LatLng(DataManager.coordinatesSwitzerlandCenter[0], DataManager.coordinatesSwitzerlandCenter[1])
    if(!this.checkPolylineHasContent() || this.viewerPosition === defaultViewerPosition) {
      this.loadedMap.setZoom(8)
    } else {
      this.loadedMap.setZoom(18)
    }*/
    
    this.loadedMap.setZoom(19)
    // if (this.checkPolylineHasContent() ){
        this.setBoundsToViewAll()
   // }
  


  }
  setBoundsToViewAll = () => {
    var bounds = new this.loadedMaps.LatLngBounds();
    if (this.cameraViewPath.map != null) {
      this.cameraViewPath
        .getPath()
        .getArray()
        .forEach(function (ll) {
          bounds.extend(ll);
        });
    }

    if (this.checkPolylineHasContent()) {
      this.state.polyline.getPath()
        .getArray()
        .forEach(function (a) {
          return bounds.extend(a);

        })
    }
    this.loadedMap.fitBounds(bounds);

  }

  clickMarker = (event) => {
    //  console.log("dropMarker : "+event.LatLng.lat())
    event.wa.target.m.rotation = 20

  }

  dropMarker = (event) => {
    //  console.log("dropMarker : "+event.LatLng.lat())
    this.updateViewerPosition(event.latLng)
    this.drawViewerPath()

    if (this.checkPolylineHasContent()) {
      this.updateDrawings()
    }

  }

  dragMarker = (event) => {

  }

  dragStartMarker = (event) => {

    console.log("dragstart : " + event)
  }


  viewerMarkerOver = (event) => {
    this.displayDescription("", "Anklicken und verschieben um Betrachtungswinkel und Distanz zu verändern")
  }
  viewerMarkerOut = (event) => {
    this.displayDescription("", "")
  }

  drawViewerPath = () => {

    var fenceCenterPosition = this.getFenceCenter()//new this.loadedMaps.LatLng(46.549007, 8.709519)
    let fc = JSON.stringify(fenceCenterPosition)
    console.log(" fc: "+fc+ " this.viewerPosition: "+this.viewerPosition)
    if(this.state.polyline.length === 0 ) {
      var dd = this.loadedMaps.geometry.spherical.computeOffset(this.viewerPosition, 5, 0);
      console.log(" dd: "+dd)
      fenceCenterPosition = dd
    }

  /*  if(isNaN(this.viewerPosition[0])){
      this.viewerPosition = this.loadedMaps.geometry.spherical.computeOffset(fenceCenterPosition, 5, 0);
    }
*/
    console.log("drawViewerPath ")
    this.viewerPath = [
      this.viewerPosition,
      fenceCenterPosition
    ];

    if (this.cameraViewPath.map != null) {
      this.cameraViewPath.setMap(null)
    }
    this.cameraViewPath = new this.loadedMaps.Polyline({
      path: this.viewerPath,
      geodesic: true,
      strokeColor: this.state.mapDrawingColor,
      strokeOpacity: 1.0,
      strokeWeight: 2
    })
    this.cameraViewPath.setMap(this.loadedMap)
    
    var cameraDist = 2500

    
    var  nearestFencePost = fenceCenterPosition
    
    if(this.state.polyline.length > 0) {
      nearestFencePost = this.getNearestFencePoint()
    }
  

    if (this.loadedMaps.geometry !== undefined && fenceCenterPosition !== undefined) {
      
      cameraDist = this.loadedMaps.geometry.spherical.computeDistanceBetween(this.viewerPosition, nearestFencePost);
      
      this.cameraHeading = this.loadedMaps.geometry.spherical.computeHeading(this.viewerPosition, fenceCenterPosition);

      console.log("HEADING " + this.cameraHeading )
      DataManager.saveToLocalStorage("fenceCenterPos", JSON.stringify(fenceCenterPosition))

      var angle = this.cameraHeading

      angle = 90 - this.cameraHeading

      if (this.cameraHeading <= 0) {
        angle = (-1 * this.cameraHeading) + 90

      }


      var distCalc =  DataManager.getDistancePixel(nearestFencePost, this.viewerPosition)

     /* this.setState({
        toolDetailDescription: "DISTANCE: " + cameraDist + " DISTANCE CALC: " + distCalc
      })*/

      // DataManager.updateLocalFenceDataCamera([0, 0, 0], Math.abs(distCalc), 90, angle, 5500, 25000000, this.viewerPosition)
      DataManager.updateLocalFenceDataCamera([0, 0, 0], Math.abs(distCalc), 90, angle, 5500, 25000000, this.viewerPosition)
      console.log("cameraDist " + cameraDist + " cameraHeading " + this.cameraHeading + " angle: " + angle);

      console.log("DISTANCE: " + distCalc)
    }
  }

  getNearestFencePoint = (position = this.viewerPosition) => {
    let pos = this.findClosest(position, this.getFineData(this.state.polyline.getPath().getArray(), 0.1))
    return pos
  }

  drawFenceCenter = (bounds) => {
    var cc = bounds.getCenter()
    var pinColor = this.state.mapDrawingColor;
    var pinLabel = "A";
     // Pick your pin (hole or no hole)
    
     var targetSVG = "M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm-7 7H3v4c0 1.1.9 2 2 2h4v-2H5v-4zM5 5h4V3H5c-1.1 0-2 .9-2 2v4h2V5zm14-2h-4v2h4v4h2V5c0-1.1-.9-2-2-2zm0 16h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4z";
     var labelOriginFilled =  this.loadedMaps.Point(12,9);
 

    if (this.centerMarker.map != null) {
      this.centerMarker.setMap(null)
    }

    var markerImage = {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
      path: targetSVG,
      anchor: new this.loadedMaps.Point(12,12),
      fillOpacity: 1,
      fillColor: pinColor,
      strokeWeight: 1,
      strokeColor: this.state.mapDrawingColor,
      scale: 1,
      labelOrigin: labelOriginFilled
  };

  this.centerMarker  = new  this.loadedMaps.Marker({
      draggable: true,
      //OPTIONAL: label: label,
      position: cc,
      icon: markerImage,
      //OPTIONAL: animation: google.maps.Animation.DROP,
  });



    this.centerMarker.addListener('click', this.clickCenterMarker);

    this.centerMarker.addListener('dragend', this.dropCenterMarker);
    this.centerMarker.addListener('dragstart', this.dragCenterMarker);


    this.centerMarker.setMap(this.loadedMap)
    console.log("BOUNDS CENTERPOINT " + cc);
    // coords viewer test 47.549007, 8.709519 // 47.549460, 8.709416

  }


  clickCenterMarker = (event) => {
    //  console.log("dropMarker : "+event.LatLng.lat())
    event.wa.target.m.rotation = 20

  }

  dropCenterMarker = (event) => {
    console.log("dropMarker : " + event.latLng.lat())

    //{"lat":47.54910904793635,"lng":8.709767013788223} {"lat":47.5491887045302,"lng":8.70976835489273}
    // DataManager.saveToLocalStorage("fenceCenterPos", JSON.stringify({lat:event.latLng.lat(), lng:event.latLng.lng()}))
    let pos = JSON.stringify({ lat: event.latLng.lat(), lng: event.latLng.lng() })//{lat:event.latLng.lat(), lng:event.latLng.lng()}
    DataManager.saveToLocalStorage("fenceCenterPos", pos)

    this.drawViewerPath()

    if (this.checkPolylineHasContent()) {
      this.updateDrawings()
    }

  }

  dragCenterMarker = (event) => {

  }

  dragCenterMarker = (event) => {

    console.log("dragCenterMarker : " + event)
  }




  // VIEWER POSITION END


  /******* MAP UTIL FUNCTIONS START */

  printDebugInformations = () => {

    // var test = DataManager.convertLatLngToCoords([47.549322284446966, 8.710145995700032, "post", 47.54936030217497, 8.7100641884374, "post", 47.54927249928526, 8.709974334558751, "post", 47.54910866051006, 8.710150019008097, "post", 47.549199179233696, 8.710253283913175, "post"], [47.549227, 8.709728])

    //console.log("ADASDASDASD " + test)

  }

  getFenceLength = (polyline) => {
    var meters = this.loadedMaps.geometry.spherical.computeLength(polyline.getPath());

    return meters
  }

  getFenceCenter = () => {
    //return new this.loadedMaps.LatLng(46.549007, 8.709519)
    var bounds = null
    var fenceCenterPosition = null
    let pos = JSON.parse(DataManager.getFromLocalStorage("fenceCenterPos"))
    
    if(this.checkPolylineHasContent()){
      bounds = this.state.polyline.getBounds()
    

    if (bounds != null && (pos === undefined || pos === null)) {
      console.log("this.state.polyline.getBounds().getCenter() " + bounds)
      fenceCenterPosition = bounds
    }

  }

    if (pos === null) {
     // var heading = this.loadedMaps.geometry.spherical.computeHeading(this.viewerPosition, 90);
      //fenceCenterPosition = { lat: parseFloat(46.90606126875733), lng: parseFloat(8.574776679687488) }
      //fenceCenterPosition = new this.loadedMaps.LatLng(cameraConfig.coordinates.lat, cameraConfig.coordinates.lng)
     fenceCenterPosition = new this.loadedMaps.LatLng(parseFloat(46.90606126875733), parseFloat(8.574776679687488))
     // fenceCenterPosition = {lat:46.90606126875733,lng:8.574776679687488}//this.viewerPosition
    }
    if (pos != null && pos !== undefined) {
      fenceCenterPosition = new this.loadedMaps.LatLng(pos.lat, pos.lng)
      var bounds2 = new  this.loadedMaps.LatLngBounds();
      bounds2.extend(fenceCenterPosition)
      this.drawFenceCenter(bounds2)
    } else {

      if (bounds != null) {
        this.drawFenceCenter(bounds)
      }
    }

    return fenceCenterPosition

  }



  /******* MAP UTIL FUNCTIONS END */

  updateDrawingManager = (drawingMode, index) => {
    this.setState({ tabValue: index }, () => console.log("STATE CHANGE: " + this.state));

    this.drawingManager.setDrawingMode(drawingMode);
  };

  // drawing control events related to polyline, map and path
  polylineEvents = polyline => {
    var _this = this;

    _this.loadedMaps.event.addListener(polyline.getPath(), "set_at", function () {
    
      console.log("polylineEvents: set_at");
      _this.savePolylinePath(polyline);
    });

    _this.loadedMaps.event.addListener(polyline, "mouseover", function (e) {
      console.log("polylineEvents: mouseover");
      //when a vertex has been clicked
      if (typeof e.vertex === "number") {
        if (!_this.state.isDragingPost) {
          _this.setState({
            toolDetailDescription: "Klicken / oder ziehen"
          })
        }

      }
    });


    _this.loadedMaps.event.addListener(polyline, "mouseout", function () {
      console.log("polylineEvents: mouseout");
      _this.displayDescription("", "", _this.state.isDragingPost)

    });

    _this.loadedMaps.event.addListener(
      polyline.getPath(),
      "insert_at",
      function () {
        console.log("polylineEvents: insert_at");
        _this.savePolylinePath(polyline);
      }
    );


    _this.loadedMaps.event.addListener(polyline, "click",  (e) =>  {
      console.log("CLICK EVENT DETAIL: " + e.latLng)
      //make the line editable
      if (_this.state.tabValue === 3) {
    
        console.log("polylineEvents: click " + typeof e.vertex);
        console.log("polylineEvents: click " + polyline);
        _this.addNewDoorMarker(e.latLng, this)
        _this.clickDoorMarker()
      }
      if (_this.state.tabValue <= 1) {
        polyline.setEditable(true);
        polyline.strokeColor = this.state.mapDrawingColor;


        // add door



        //when a vertex has been clicked
        if (typeof e.vertex === "number") {

          //when the first or last vertex has been clicked
          if (!e.vertex || e.vertex === polyline.getPath().getLength() - 1) {

            //when the first vertex has been clicked reverse the path to
            //be able do push a vertex
            if (e.vertex === 0) {
              var p = polyline.getPath().getArray();
              p.reverse();
              polyline.setPath(p);
            }
            if (_this.state.tabValue === 0) {
              //push a vertex

              if (polyline.getPath().getArray()[polyline.getPath().length - 2] !== e.latLng) {
                polyline.getPath().push(e.latLng);
              }

            }

            //observe the mousemove of the map to set the latLng of the last vertex
            var move = _this.loadedMaps.event.addListener(
              _this.loadedMap,
              "mousemove",
              function (e) {
                polyline
                  .getPath()
                  .setAt(polyline.getPath().getLength() - 1, e.latLng);
                console.log("polylineEvents: mousemove");
                _this.setState({
                  toolDetailDescription: "Maus bewegen um den Zaun zu verändern, Rechtsklick zum beenden",
                  isDragingPost: true
                })
              }
            );
            //stop editing on rightclick
            _this.loadedMaps.event.addListener(
              polyline,
              "rightclick",
              function (e) {

                _this.setState({
                  toolDetailDescription: "",
                  isDragingPost: false
                })


                _this.loadedMaps.event.removeListener(move);

                console.log("polylineEvents: mouseup");
                //polyline.setEditable(false);
                _this.savePolylinePath(polyline, polyline);
                _this.handleChangeTool(1)
                if (_this.doorMarkers.length > 0) {
                  _this.updateDoorMarkers(e.latLng)
                }
              }
            );
          }
        }
      }
    });
  };

  savePolylinePath = polyline => {
    // workaround javascript circular json bug

    polyline.setOptions({strokeColor:this.state.mapDrawingColor});
    this.setState({ polyline: polyline });
    var seen = [];
   
    var positionItem = polyline.getPath().getArray()

    var objToSave = JSON.stringify(positionItem, function (key, val) {
      if (val != null && typeof val == "object") {
        if (seen.indexOf(val) >= 0) {
          return;
        }
        seen.push(val);
      }
      return val;
    });

    DataManager.saveToLocalStorage("fenceLength", this.getFenceLength(polyline))
    DataManager.updateLocalFenceData(objToSave)
    this.toggleMarkers(true);
    if (this.checkPolylineHasContent()) {
      this.updateDrawings()
    }

    this.handleChangePlan("")

  };

  toggleMarkers = markerVisible => {
    if (this.markers.length > 0) {
      if (!markerVisible) {
        for (var i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(null);
        }

        this.markers = [];
      }

      var polyline = this.state.polyline;
      var markerArray = polyline.getPath().getArray();
      markerArray.map(markerArray => this.setMarker(markerArray, markerVisible));
    }
  };

  setMarker = (markerArrayItem, markerVisible) => {
    var marker = new this.loadedMaps.Marker({
      position: markerArrayItem
    });

    this.markers.push(marker);

    if (!markerVisible) {
      marker.setMap(null);
    } else {
      marker.setMap(this.loadedMap);
    }
  };

  //47.547540, 8.707169
  //47.549136, 8.709476

  static defaultProps = {
    center: {
      lat: DataManager.coordinatesSwitzerlandCenter[0],
      lng: DataManager.coordinatesSwitzerlandCenter[1]
    },
    zoom: 8
  };

  handleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });


    if (!this.checkPolylineHasContent()) {
      this.handleChangeTool(0)
    } else {
      this.handleChangeTool(1)
    }


  };

  handleDialogDelete = () => {
    this.setState({ dialogOpen: false });

    this.removePolylines()
    this.handleChangeTool(0)
    //
  };

  handleStartSearchLocationDialog = () => {

    this.setState({ dialogLocationSearchOpen: true })

  }

  handleCloseSearchLocationDialog = () => {

    this.setState({ dialogLocationSearchOpen: false })

    this.updateViewerPosition(this.newPosition)
    this.handleChangeTool(1)
  }

  handleCancelSearchLocationDialog = () => {
    this.setState({ dialogLocationSearchOpen: false })
    this.handleChangeTool(1)

  }

  handleOnCameraLocationChange = (newPosition) => {
    this.newPosition = newPosition

    DataManager.updateLocalFenceData("[]")
    if(this.checkPolylineHasContent()) {
      this.removePolylines()
   
    }
  


    DataManager.saveToLocalStorage("locationSet", true)
    console.log("onCameraLocationChange " + newPosition)
    this.drawViewerPath()
  }

  getStyle(isActive) {
    return isActive ? styles.active_tab : styles.default_tab
  }

  checkPolylineHasContent = () => {
    // console.log("POLYLINE: "+this.state.polyline)
    if (this.state.polyline.length === 0) {
      return false
    }

    return true
  }

  render(props) {
    const { classes } = this.props;
    return (

      <div className="ViewPlanContainer" style={{ height: this.state.height}}>
        <CssBaseline />
        <AppBar position="relative" id="capture2"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}>
          <Toolbar disableGutters={!this.state.open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography color="inherit" noWrap>
              {this.state.toolDescription}
            </Typography>
          </Toolbar>
        </AppBar>

        <div className="ViewPlanContainerDescription">{this.state.toolDetailDescription}</div>
        <Drawer

          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
              [classes.drawerPaper]: classes.drawerPaper
            }),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {classes.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List >
            <Tooltip title="Bearbeiten" placement="right">
              <StyledListItem button onClick={() => this.handleChangeTool(1)}
                selected={this.state.tabValue === 1} disabled={(!this.checkPolylineHasContent())}>
                <ListItemIcon><EditIcon /></ListItemIcon>
                <ListItemText primary="Bearbeiten" />
              </StyledListItem>
            </Tooltip>
            <Tooltip title="Hinzufügen" placement="right">
              <StyledListItem button onClick={() => this.handleChangeTool(0)}
                selected={this.state.tabValue === 0}>
                <ListItemIcon><AddIcon /></ListItemIcon>
                <ListItemText primary="Hinzufügen" />
              </StyledListItem>
            </Tooltip>
            {/* <Tooltip title="Tor" placement="right">
              <StyledListItem button onClick={() => this.handleChangeTool(3)}
                selected={this.state.tabValue === 3} disabled={!this.checkPolylineHasContent()}>
                <ListItemIcon><EditDoor /></ListItemIcon>
                <ListItemText primary="Tor hinzufügen / bearbeiten" />
              </StyledListItem>
            </Tooltip>*/}
            {/*<Tooltip title="Kamera Position" placement="right">
              <StyledListItem button onClick={() => this.handleChangeTool(2)}
                selected={this.state.tabValue === 2} disabled={!this.checkPolylineHasContent()}>
                <ListItemIcon><ViewerIcon /></ListItemIcon>
                <ListItemText primary="Kamera Position" />
              </StyledListItem>
            </Tooltip>*/}

          </List>
          <Divider />
          <List>
            <Tooltip title="Ort suchen" placement="right">
              <StyledListItem button onClick={() => this.handleChangeTool(5)}
                selected={this.state.tabValue === 5}>
                <ListItemIcon><MyLocationIcon /></ListItemIcon>
                <ListItemText primary="Ort suchen" />
              </StyledListItem>
            </Tooltip>
          </List>
          <Divider />
          <List>
            <Tooltip title="Infos" placement="right">
              <StyledListItem button onClick={() => this.handleChangeTool(8)}
                selected={this.state.tabValue === 8} disabled={!this.checkPolylineHasContent()}>
                <ListItemIcon><InfoIcon /></ListItemIcon>
                <ListItemText primary="Infos ein / aus" />
              </StyledListItem>
            </Tooltip>
            <Tooltip title="Rückgängig" placement="right">
              <StyledListItem button onClick={() => this.handleChangeTool(4)}
                selected={this.state.tabValue === 4} disabled={!this.checkPolylineHasContent()}>
                <ListItemIcon><UndoIcon /></ListItemIcon>
                <ListItemText primary="Rückgängig" />
              </StyledListItem>
            </Tooltip>
            <Tooltip title="Löschen" placement="right">
              <StyledListItem button onClick={() => this.handleChangeTool(6)}
                selected={this.state.tabValue === 6} disabled={!this.checkPolylineHasContent()}>
                <ListItemIcon><DeleteIcon /></ListItemIcon>
                <ListItemText primary="Löschen" />
              </StyledListItem>
            </Tooltip>
          </List>
        </Drawer>

        <div id="MapContainer" style={{ height: this.state.height - 60 }}>
          <div className="MapControls">
            <FormControlLabel className={classes.checkbox} disabled={!this.checkPolylineHasContent()}
              control={
                <Checkbox

                  checked={this.state.showInfos}
                  onChange={this.toggleInfos}
                  value="asd"
                  color="primary"
                />
              }
              label="Infos anzeigen"
            />
            {/*<FormControlLabel className={classes.checkboxDoor} disabled={!this.checkPolylineHasContent()}
              control={
                <Checkbox
                  checked={this.state.showDoorMarkers}
                  onChange={this.toggleDoorMarkers}
                  value="asd"
                  color="primary"
                />
              }
              label={this.getDoorCheckboxLabel()}
            /> */}
          </div>

          <GoogleMapReact id="capture"
            options={this.createMapOptions}
            bootstrapURLKeys={{
              key: "AIzaSyCf5VarQ3amrnPXN2xg7HsQFXJgvhToWZU",
              libraries: ["drawing", "places", "geometry"].join(",")
            }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            onGoogleApiLoaded={({ map, maps }) =>
              this.onGoogleMapsLoaded(map, maps)
            }
            yesIWantToUseGoogleMapApiInternals
          />

        </div>

        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Achtung alles wird gelöscht!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Soll der Zaun komplett gelöscht werden?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              Abbrechen
            </Button>
            <Button onClick={this.handleDialogDelete} color="primary" autoFocus>
              Löschen
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogLocationSearchOpen}
          onClose={this.handleCloseSearchLocationDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={'lg'}
        >
          <DialogTitle id="alert-dialog-title">{"Ort suchen"}</DialogTitle>
          <DialogContent>
            <LocationSearch onCameraLocationChange={this.handleOnCameraLocationChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancelSearchLocationDialog} color="primary">
              Abbrechen
            </Button>
            <Button onClick={this.handleCloseSearchLocationDialog} color="primary" autoFocus>
              Speichern
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogDoorOpen}
          onClose={this.handleCloseDoorDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle id="alert-dialog-title">{"Tor"}</DialogTitle>
          <DialogContent>
            Tor speichern oder löschen. Standard 1.5 Meter breit.
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRemoveDoorDialog} color="primary">
              Entfernen
            </Button>
            <Button onClick={this.handleSaveDoorDialog} color="primary" autoFocus>
              Speichern
            </Button>
            <Button onClick={this.handleContinueDoorDialog} color="primary" autoFocus>
              Weiter bearbeiten
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog
          open={this.state.dialogDoorDescription}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle id="alert-dialog-title">{"Tor"}</DialogTitle>
          <DialogContent>
            Ein Tor wird  mit Standard 1.5m hinzugefügt. Sie können das Tor auf dem Zaun frei verschieben. Es dient nr zur Information, damit wir ungefähr wissen ob und wo sie ungefähr ein Tor benötigen.
          </DialogContent>
          <DialogActions>

            <Button onClick={this.handleDialogDoorDescriptionClose} color="primary" autoFocus>
              ok
            </Button>
          </DialogActions>
        </Dialog>

      </div>

    );
  }
}

export default withStyles(styles, { withTheme: true })(PlanC);