import React, { Component } from 'react'

import ImageUpload from "./ImageUpload"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import ResetIcon from '@material-ui/icons/Delete'
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import clsx from 'clsx';
import classNames from 'classnames';

import Drawer from '@material-ui/core/Drawer';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';


import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'


/*import pep from "../../fence3D/includes/js/pep.js"
import aftc from "../../fence3D/includes/js/aftc.js"

*/

import { DataManager } from '../../DataManager'
import Fence3D from '../../fence3D/fence'
import ChooseS from './ChooseS';
import { FormGroup } from '@material-ui/core';
import DragDropContainer from '../DragDropContainer';
import { ActionSettingsBrightness } from 'material-ui/svg-icons';


var path = require('path');

const drawerWidth = 320;

const visual2D_containerWidth = "visual2D_containerWidth";
const visual2D_fencePartsCount = "visual2D_fencePartsCount"
const visual2D_container_left = "visual2D_container_left"
const visual2D_container_top = "visual2D_container_top"

const styles = theme => ({
  root: {

    display: 'flex',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  chip: {
    margin: theme.spacing.unit,
    float: 'left'
  },
  chip2: {
    margin: theme.spacing.unit,
    float: 'right'
  }
  ,
  switch: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    flexDirection: 'row'
  }
  ,
  appBar: {

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {

    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {

    top: 135,
    width: drawerWidth,
  },
  drawerHeader: {
    top: 135,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {

    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  addRemoveButton: {
    margin: '10px'
  }
});


class Visualize extends Component {

  fenceParts = []

  constructor(props) {
    super(props);
    this.handleDidDrop = this.handleDidDrop.bind(this);
    this.state = {
      width: 0, height: 0, dialogPhotoOpen: false,
      photoBackground: null,
      selectedPhoto: null,
      displayPhoto: false,
      photoStyle: 'contain',
      refresh: true,
      open: true,
      show3D: false,
      selectedFenceImage: DataManager.getFenceImageSrc(),
      contentHeight: 0,
      boxesWidth: 500,
      fenceImageContainer: [], 
      imageContrast: 100
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.boxes = {
      a: { top: 20, left: 80, title: '', width: this.state.boxesWidth }
    }

    DataManager.getSettingsFirebase(DataManager.getFromLocalStorage("boardId"), (doc) => {
      console.log("SETTINGS: " + doc.data().settings)
      if (doc.data().settings !== undefined) {
        console.log("SETTINGS: " + doc.data().settings.visual2D_containerWidth)

        this.setState({
          boxesWidth: doc.data().settings.visual2D_containerWidth

        })
        this.boxes.a.left = doc.data().settings.visual2D_container_left
        this.boxes.a.top = doc.data().settings.visual2D_container_top
        var fenceParts = []
        var counter = 1

        if (doc.data().settings.visual2D_fencePartsCount > 1) {
          counter = doc.data().settings.visual2D_fencePartsCount
        }

        for (var i = 0; i < counter; i++) {

          fenceParts.push(<img src={this.state.selectedFenceImage} className="ImageDrag" alt="drag fence" style={{ width: this.state.boxesWidth / 3 }} />)

        }

        this.setState({
          fenceImageContainer: fenceParts
        }, () => {
          this.refreshFenceParts()
        })
      }

    })


    if (this.state.fenceImageContainer.length === 0) {
      var fenceParts = this.state.fenceImageContainer//[<img src={this.state.selectedFenceImage} className="ImageDrag" alt="drag fence" style={{width:this.state.boxesWidth / 3}}/>, <img src={this.state.selectedFenceImage} className="ImageDrag" alt="drag fence" style={{width:this.state.boxesWidth / 3}}/>]//this.state.fenceImageContainer//[<img src={this.state.selectedFenceImage} className="ImageDrag" alt="drag fence" style={{width:this.state.boxesWidth / 3}}/>]
      fenceParts.push(<img src={this.state.selectedFenceImage} className="ImageDrag" alt="drag fence" style={{ width: this.state.boxesWidth / 3 }} />)
      this.setState({
        fenceImageContainer: fenceParts
      })
    }

  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.document.addEventListener('myEvent', this.handleDragEvent, false)
    window.document.addEventListener('rotateGroupDataEvent', this.handleRotateEvent, false)

    var photo = DataManager.getFromLocalStorage("backgroundPhoto")

    if (photo != null) {
      this.setState({ selectedPhoto: photo, photoBackground: photo, displayPhoto: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const height = window.innerHeight - document.getElementById('ViewHeaderContainer').clientHeight - 115;
    this.setState({ width: window.innerWidth, height: height, contentHeight: height });
  }

  handleDragEvent = (e) => {
    var containerPosition = e.detail.containerPosition
    console.log("containerPosition.x " + containerPosition.x + "containerPosition.y " + containerPosition.y + " containerPosition.z" + containerPosition.z) // outputs: {foo: 'bar'}

    DataManager.updateLocalFenceDataGroup(true, 0, true, 0, [containerPosition.x, containerPosition.y, containerPosition.z], [0, 0, 0])

  }
  radians_to_degrees(radians) {
    var pi = Math.PI;
    return radians * (180 / pi);
  }

  handleRotateEvent = (e) => {
    var camera = e.detail
    console.log("camera._position " + camera._position)
    if (camera.alpha !== undefined) {
      //  console.log("camera.alpha.toFixed(3) " + camera.alpha.toFixed(3) + " camera.beta.toFixed(3) " + camera.beta.toFixed(3) + " camera.radius.toFixed(3) " + camera.radius.toFixed(3)) // outputs: {foo: 'bar'}

      let cameraAngle = this.radians_to_degrees(camera.beta)
      let cameraRotation = this.radians_to_degrees(camera.alpha) + 90;

      var fenceData = DataManager.getFenceDataFromLocalStorage()
      //   fenceData.config.camera.pos = camera.position
      fenceData.config.camera.angle = cameraAngle
      fenceData.config.camera.rotation = cameraRotation
      fenceData.config.camera.radius = camera.radius
      DataManager.updateFenceDataSimple(fenceData)
    }

  }
  handleClickOpenImageUpload = () => {
     this.setState({ dialogPhotoOpen: true });
    

  };

  handleCancelPhotoDialog = () => {
    this.setState({ dialogPhotoOpen: false });

  };

  handleSavePhotoDialog = () => {
    this.setState({ dialogPhotoOpen: false });
    if (this.state.selectedPhoto != null) {
      this.setState({ photoBackground: this.state.selectedPhoto, displayPhoto: true });
      DataManager.saveToLocalStorage("backgroundPhoto", this.state.selectedPhoto)
    }

  };

  onSceneMount = (e) => {
  }

  handleControlClick = () => {

    //  DataManager.updateLocalFenceDataCamera([0, 0, 0], 0, 0, 0, 0, 0)

    DataManager.updateLocalFenceDataGroup(true, 0, true, 0, [0, 0, 0], [0, 0, 0])
    this.child.getAlert()
  };

  handleImageFillClick = () => {
    if (this.state.photoStyle === 'contain') {
      this.setState({ photoStyle: 'cover' })
    } else {
      this.setState({ photoStyle: 'contain' })
    }

  };
  handleImageToggleClick = () => {

    this.setBackgroundImageVisible()

  };

  handlePhotoSelected = (selectedPhoto) => {
    this.setState({ selectedPhoto: selectedPhoto });
  };

  handlePhotoSelectedMetadadta = (newPosition, newHeading) => {
    let pos = { lat() { return newPosition[0] }, lng() { return newPosition[1] } }
    console.log("POS: " + pos.lat())
    let center = JSON.parse(DataManager.getFromLocalStorage("fenceCenterPos"))
    let pos2 = { lat() { return center.lat }, lng() { return center.lng } }
    let distCalc = DataManager.getDistancePixel(pos, pos2)

    let pos1 = { lat: newPosition[0], lng: newPosition[1] }
    var angle = 90 - this.cameraHeading

    if (this.cameraHeading <= 0) {
      angle = (-1 * this.cameraHeading) + 90

    }

    DataManager.updateLocalFenceDataCamera([0, 0, 0], Math.abs(distCalc), 90, angle, 5500, 25000000, pos1)
  }

  setBackgroundImageVisible = () => {
    if (this.state.displayPhoto === true) {
      this.setState({ photoBackground: '', displayPhoto: false });
    } else {

      this.setState({ photoBackground: this.state.selectedPhoto, displayPhoto: true });
    }

  };


  handleDrawerOpen = () => {
    this.setState({ open: true }, () => {
      if(this.child !== undefined) {
        this.child.getAlert()
      }
    })


  }

  handleToggle3DView = () => {
    this.setState({ show3D: !this.state.show3D }, () => {

      if (this.state.show3D) {
        this.child.getAlert()
   
        
      }
    })
  }

  handleDrawerClose = () => {
    this.setState({ open: false }, () => {
      if(this.child !== undefined) {
        this.child.getAlert()
      }
      
    })
  }

  onFenceChanged = () => {

    this.setState({
      selectedFenceImage: DataManager.getFenceImageSrc()
    }, () => {
      this.refreshFenceParts()
    })

    if (this.state.show3D) {
      this.child.getAlert()
    }
  }

  refreshFenceParts = () => {

    this.fenceParts = []
    this.state.fenceImageContainer.forEach(() => {
      this.fenceParts.push(<img src={this.state.selectedFenceImage} className="ImageDrag" alt="drag fence" style={{ width: this.state.boxesWidth / 3 }} />)
    })

    this.setState({
      fenceImageContainer: this.fenceParts
    }, () => {
      DataManager.saveDataSettingsOnly(DataManager.getFromLocalStorage("boardId"), {
        visual2D_containerWidth: this.state.boxesWidth,
        visual2D_fencePartsCount: this.state.fenceImageContainer.length,
        visual2D_container_top: this.boxes.a.top,
        visual2D_container_left: this.boxes.a.left
      }, (err, data, response) => {
        if (err === null) {
          console.log("SAVE saveDataFence SUCCESS")
          
        } else {
          console.log("ERROR")
        }
      })
    })

  
  }

  handleFenceHeightRemove = () => {

    this.setState({
      boxesWidth: this.state.boxesWidth - 10
    }, () => {
      this.refreshFenceParts()
    })
  }

  handleFenceHeightAdd = () => {

    this.setState({

      boxesWidth: this.state.boxesWidth + 10
    }, () => {
      this.refreshFenceParts()
    })
  }

  handleContrastRemove = () => {

    this.setState({
      imageContrast: this.state.imageContrast - 10
    }, () => {
      //this.refreshFenceParts()
    })
  }

  handleContrastAdd = () => {

    this.setState({

      imageContrast: this.state.imageContrast + 10
    }, () => {
     // this.refreshFenceParts()
    })





  }

  handleFenceRemove = () => {

    this.fenceParts = this.state.fenceImageContainer//[<img src={this.state.selectedFenceImage} className="ImageDrag" alt="drag fence" style={{width:this.state.boxesWidth / 3}}/>]

    if (this.fenceParts.length > 1) {
      this.fenceParts.splice(this.fenceParts.length - 1, 1)
    }



    this.setState({
      fenceImageContainer: this.fenceParts
    }, () => {
      this.refreshFenceParts()
    })

  }

  handleFenceAdd = () => {

    this.fenceParts = this.state.fenceImageContainer//[<img src={this.state.selectedFenceImage} className="ImageDrag" alt="drag fence" style={{width:this.state.boxesWidth / 3}}/>, <img src={this.state.selectedFenceImage} className="ImageDrag" alt="drag fence" style={{width:this.state.boxesWidth / 3}}/>]//this.state.fenceImageContainer//[<img src={this.state.selectedFenceImage} className="ImageDrag" alt="drag fence" style={{width:this.state.boxesWidth / 3}}/>]
    this.fenceParts.push(<img src={this.state.selectedFenceImage} className="ImageDrag" alt="drag fence" style={{ width: this.state.boxesWidth / 3 }} />)
    this.setState({
      fenceImageContainer: this.fenceParts
    }, () => {
      this.refreshFenceParts()
    })

    console.log("handleFenceAdd " + this.fenceParts.length)
  }

  handleDidDrop = (top, left) => {
    this.boxes.a.top = top;
    this.boxes.a.left = left
 
      this.refreshFenceParts()
    





  }

  render() {
    const { classes, theme } = this.props;



    var divStyle = {
      backgroundImage: 'url(' + this.state.photoBackground + ')',
      backgroundPosition: 'center',
      backgroundSize: this.state.photoStyle,
    
      rotation: '0',
      width: '-webkit-fill-available',
      height: this.state.contentHeight,
      backgroundRepeat: 'no-repeat',
      border: 'none'
      // backgroundImage:'url(/fence3D/assets/images/bgTest.jpg)'
    }



    return (

      <div className="ViewVisualizeContainer" >

        <AppBar position="relative" id="VisualizeAppBar"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}>
          <Toolbar position="relative">

            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              position="relative"
              className={classNames(classes.menuButton, this.state.open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            {this.state.show3D === false?
<div className="testingit">
            <Fab size="small" color="primary" aria-label="remove" className={classes.addRemoveButton} hidden={true} >
              <RemoveIcon color="white" onClick={this.handleFenceRemove} />
            </Fab>
             {'Zaunlänge'}
        <Fab size="small" color="primary" aria-label="add" className={classes.addRemoveButton}>
              <AddIcon color="white" onClick={this.handleFenceAdd} />
            </Fab>

            <Fab size="small" color="primary" aria-label="remove" className={classes.addRemoveButton}>
              <RemoveIcon color="white" onClick={this.handleFenceHeightRemove} />
            </Fab>
              {'Zaunhöhe'}
        <Fab size="small" color="primary" aria-label="add" className={classes.addRemoveButton}>
              <AddIcon color="white" onClick={this.handleFenceHeightAdd} />
            </Fab>

            <Fab size="small" color="primary" aria-label="remove" className={classes.addRemoveButton}>
              <RemoveIcon color="white" onClick={this.handleContrastRemove} />
            </Fab>
            {'Kontrast'}
       
            {this.state.imageContrast+' %'}
        <Fab size="small" color="primary" aria-label="add" className={classes.addRemoveButton}>
              <AddIcon color="white" onClick={this.handleContrastAdd} />
            </Fab>
            </div>
            : null}
  
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          id="VisualizeDrawer"
          variant="persistent"
          anchor="left"
          open={this.state.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              {styles.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <Chip

            avatar={<Avatar><CameraIcon /></Avatar>}
            label="Foto hochladen"
            onClick={this.handleClickOpenImageUpload}
            className={classes.chip2}
            color="primary"
          />

          <FormGroup>

            <FormControlLabel
              className={classes.switch}
              value=""

              control={<Switch
                checked={this.state.show3D === true}
                onChange={this.handleToggle3DView}

                value="toggle3DView"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />}
              label="3D Ansicht"
              labelPlacement="start"
            />
            {this.state.selectedPhoto && this.state.displayPhoto ?
              <FormControlLabel
                className={classes.switch}
                value=""

                control={<Switch
                  checked={this.state.photoStyle === 'cover'}
                  onChange={this.handleImageFillClick}

                  value="photoStyle"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />}
                label="Foto Ausfüllen"
                labelPlacement="start"
              /> : null && true}

            {this.state.selectedPhoto ?
              <FormControlLabel
                className={classes.switch}
                value=""
                control={<Switch
                  checked={this.state.displayPhoto === true}
                  onChange={this.handleImageToggleClick}

                  value="photoDisplay"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />}
                label="Foto anzeigen"
                labelPlacement="start"
              />
              : null}
          </FormGroup>
          <Divider />
        
          <ChooseS onFenceChanged={this.onFenceChanged} />
          <Divider />
          <Chip
            position="absolute"
            avatar={<Avatar><ResetIcon /></Avatar>}
            label="reset"
            onClick={this.handleControlClick}
            className={classes.chip}
            color="primary"
          />

        </Drawer>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: this.state.open,
          })}
        >


          <div style={divStyle} id="fenceBackground"  ref="fenceBackground">
            {
              this.state.show3D ? (<Fence3D ref={instance => { this.child = instance; }} openWidth={this.state.open} readOnly="false" />) : (<div  > 		<DndProvider backend={HTML5Backend}>
                <DragDropContainer hideSourceOnDrag={false} image={this.state.selectedFenceImage} boxesCollection={this.boxes} didDrop={this.handleDidDrop} contentHeight={this.state.contentHeight} boxWidth={this.state.boxesWidth} fenceImageContainer={this.state.fenceImageContainer} contrast={''+this.state.imageContrast} />
              </DndProvider> </div>)
            }

          </div>

        </main>
        <Dialog

          open={this.state.dialogPhotoOpen}
          onClose={this.handleClosePhotoDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Foto hochladen</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Hier können sie ein Foto hochladen
            </DialogContentText>
            <ImageUpload handlePhotoSelected={this.handlePhotoSelected} handlePhotoSelectedMetadadta={this.handlePhotoSelectedMetadadta} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancelPhotoDialog} color="primary">
              Abbrechen
            </Button>
            <Button onClick={this.handleSavePhotoDialog} color="primary" autoFocus>
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    )
  }
}

export default withStyles(styles, { withTheme: true })(Visualize)