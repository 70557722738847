import React, { Component } from 'react'
import { DataManager } from '.././../DataManager'
import '@firebase/functions'
import Axios from 'axios'
import clsx from 'clsx';
import axios from 'axios'
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import firebase from './../Firebase/Firebase';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DetailOverView from '../DetailOverView';

import  pdfMake  from 'pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";

import zaunteam_logo_de from '../../media/zaunteam_logo_simple.png'
import { project } from 'gcp-metadata';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

var path = require('path');
// Create styles




class ContactForm extends Component {

    textureUrl = path.join(__dirname, './fence3D/assets/fence_data')
    constructor(props) {
        super(props)

        //this.ref = firebase.firestore().collection('boards');
        this.state = {
            boardid:props.boardid,
            loading: false,
            setLoading: false,
            success: false,
            setSuccess: false,
            dialogOpen: false,
            key: '',
            description: '',
            author: '',
            date: '',
            name: '',
            email: '',
            address: '',
            street: '',
            plz: '',
            city: '',
            country: '',
            phone: '',
            usercomment: '', user_id: '', fenceData: '', userImage: '', canLoadPDF: true, mapImageSrc: "", dialogOverviewOpen: false
        };

  
    }



    componentDidMount() {
        console.log("HEADERVALUE " + this.testValue)
        console.log("this.state.boardid " + this.state.boardid)
        var boardId = this.state.boardid
        if(boardId === "") {
             boardId = DataManager.getFromLocalStorage("boardId")

            console.log("boardId " + boardId)

            if(boardId !== undefined) {
                console.log("boardId undefined " + boardId)
                this.setState({
                    boardid:boardId
                })
            }


        }

        DataManager.getFirebaseData(boardId, (doc) => {

            var userImage = DataManager.getFromLocalStorage("backgroundPhoto")
            if (userImage === undefined) {
                userImage = ""
            }
          
                if (doc) {
                    const board = doc.data();
                    var objToSave = DataManager.getFromLocalStorage("fenceData")
                    var description = ""
                    if (board.description !== undefined) {
                        description = board.description
                    }
    
                    this.setState({
                        key: doc.id,
                        description: description,
                        author: board.author,
                        date: board.date,
                        name: board.name,
                        email: board.email,
                        address: board.address,
                        street: board.street ? board.street : "",
                        plz: board.plz ? board.plz : "",
                        city: board.city ? board.city : "",
                        country: board.country ? board.country : "",
                        phone: board.phone ? board.phone : 0,
                        usercomment: board.usercomment ? board.usercomment : "",
                        user_id: board.email,
                        fenceData: objToSave,
                        userImage: userImage,
                        partnerAdressItem:null
    
                    });
    
               
    
                    //this.handleOpenDialogOverview(true)
                    this.setRegionPartnerInfos()
                } else {
                    console.log("No such document!");
                }
         
    
            
        })//firebase.firestore().collection('boards').doc(this.state.boardid);
       
    }

    setRegionPartnerInfos = () => {

        var _this = this;

        if(this.state.plz.length > 3) {
            var convert = require('xml-js');
        var c = 'CH'
        if(this.state.country === 'Österreich') {
            c = 'AT'
        }

        if(this.state.country === 'Deutschland') {
            c = 'DE'
        }


        axios.get('https://www.zaunteam.com/magento-schnittstelle/plzsuche_xml.php?plz='+this.state.plz+'&land='+c, {crossdomain:true})
            .then(function (res) {

                var result1 = convert.xml2js(res.data, { compact: true, spaces: 0 });

           
                 console.log("DATA " + result1)
                _this.setState({
                    partnerAdressItem: result1
                });

            })
        }



    }

    handleChangeContactForm = buttonId => {
        this.props.handleChangeContactForm(buttonId)
        
    };
    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);


        if(e.target.name === 'plz' && e.target.value.length > 3) {
            console.log("SET PARTNER INFOS "+e.target.value)
            this.setRegionPartnerInfos()
        }

    }

    onSubmit = (e) => {
        e.preventDefault();

        if (!this.state.loading) {
            this.setState({ success: false });
            this.setState({ loading: true });

        }

        this.setState({
            date: new firebase.firestore.FieldValue.serverTimestamp()

        });

        console.log("DATE" + this.state.date)

        const {
            description, author, date,
            name,
            email,
            address,
            street,
            plz,
            city,
            country,
            phone,
            usercomment,
            user_id, fenceData, userImage
        } = this.state;

        DataManager.saveUserContactData(this.state.boardid, description, author, date,
            name,
            email,
            address,
            street,
            plz,
            city,
            country,
            phone,
            usercomment, (err, data, response) => {
                console.log("SAVE saveUserContactData SUCCESS")
                if (err === null) {
                    this.setState({ success: true, loading: false, canLoadPDF: true, firebaseData:data });
                    this.handleClickOpen()
                    this.sendEmail()

                    DataManager.saveDataFence(this.state.boardid, date, user_id, fenceData, userImage, (err, data, response) => {
                        if (err === null) {
                            console.log("SAVE saveDataFence SUCCESS")
                            this.setState({firebaseData:data });
                        }
                    })

                    console.log("SAVE saveUserContactData SUCCESS")
                }
            })
    }

    handleClickOpen = () => {
        this.setState({ dialogOpen: true });
    };

    handleClose = () => {
        this.setState({ dialogOpen: false });
    };

    handleCloseDialogOverview = () => {
        this.setState({ dialogOverviewOpen: false });
    };

    getBase64(url, callback) {
        var httpRequest = new XMLHttpRequest();
        httpRequest.onload = function() {
           var fileReader = new FileReader();
              fileReader.onloadend = function() {
                 callback(fileReader.result);
              }
              fileReader.readAsDataURL(httpRequest.response);
        };
        httpRequest.open('GET', url);
        httpRequest.responseType = 'blob';
        httpRequest.send();
    }

    getAdressText = () => {
        var text = [
                    { text: 'Zaunteam Neftenbach', style:'normalText' },
                    { text: 'Tösswiesenstrasse 10', style:'normalText' },
                    { text: '8413 Neftenbach', style:'normalText' },
                    { text: '', style:'normalText' },
                    { text: 'Tel: +41523044455', style:'normalText' },
                    { text: 'Mail: neftenbach@zaunteam.ch', style:'normalText' }
                ]
                if(this.state.partnerAdressItem !== null && this.state.partnerAdressItem.partner !== undefined){
                    text = [
                        { text: this.state.partnerAdressItem.partner.name1._text, style:'normalText' },
                        { text: this.state.partnerAdressItem.partner.strasse._text, style:'normalText' },
                        { text: this.state.partnerAdressItem.partner.plz._text+' '+this.state.partnerAdressItem.partner.ort._text, style:'normalText' },
                        { text: '', style:'normalText' },
                        { text: 'Tel: '+this.state.partnerAdressItem.partner.tel._text, style:'normalText' },
                        { text: 'Mail: '+this.state.partnerAdressItem.partner.mail._text, style:'normalText' }
                    ]
                } else {
                    text = { text: '', style:'normalText' }
                }
        return text
                }

        getAdressTextMail = () => {
        var textMail = 'Zaunteam Neftenbach',
        textMail = textMail+"<br></br>"
        textMail = textMail+"Tösswiesenstrasse 10"
        textMail = textMail+"8413 Neftenbach"
        textMail = textMail+""
        textMail = textMail+"Tel: +41523044455"
        textMail = textMail+"Mail: neftenbach@zaunteam.ch"
                
                if(this.state.partnerAdressItem !== null && this.state.partnerAdressItem.partner !== undefined) {
                    textMail = this.state.partnerAdressItem.partner.name1._text
                    textMail = textMail+"<br>"
                    textMail = textMail+""+this.state.partnerAdressItem.partner.strasse._text
                    textMail = textMail+"<br>"
                    textMail = textMail+""+this.state.partnerAdressItem.partner.plz._text+' '+this.state.partnerAdressItem.partner.ort._text
                    textMail = textMail+"<br>"
                    textMail = textMail+"<br>"
                    textMail = textMail+"<br>"
                    textMail = textMail+'Tel: '+this.state.partnerAdressItem.partner.tel._text
                    textMail = textMail+"<br>"
                    textMail = textMail+'Mail: '+this.state.partnerAdressItem.partner.mail._text
                    
                } else {
                    textMail = ""
                }
        return textMail
                }

    handleOpenDialogOverview = () => {
        var imgLogo
        var imgZauntyp
        this.getBase64(zaunteam_logo_de, (result) => {
       
            imgLogo = result


     


            this.getBase64(this.getFenceImageSrc(), (result2) => {
             
                imgZauntyp = result2



                var dd = {
               
                    header: {image:imgLogo, width: 150, style:'headerImage'},
                    content: [
                    { text: 'Dein Zaunteam Projekt', style:'header' },
                    { text: 'Guten Tag '+this.state.name, style:'normalText' },
                    { text: 'Vielen Dank für die Nutzung unseres Zaunplaners.', style:'normalText' },
                    { text: 'Dein Zaunprojekt: id = '+this.state.boardid, style:'header2' },
                    { text: 'Zauntyp:', style:'normalText' },
                    { text: 'Anwendung: '+ DataManager.getFenceCatName() +'   Zaunlange: '+ DataManager.getFenceLength()+' m   Zaunhöhe: '+DataManager.getFenceHeight()+' m', style:'normalText' },
                    {/* text: 'Zaunlange: '+ DataManager.getFenceLength()+' m', style:'normalText' },
                    { text: 'Zaunhöhe: '+DataManager.getFenceHeight()+' m', style:'normalText' */},
                    { text: "Bezeichnung: "+this.getZauntypName(), style:'normalTextImage' },
                    {image:imgZauntyp, width: 150, style:'fenceImage'},
                   
                    { text: 'Bei Fragen oder einer Beratung, wende dich bitte an dein Zaunteam in der Nähe:', style:'normalText' },
                  
                    { text: '', style:'normalText' },  { text: '', style:'normalText' },  { text: '', style:'normalText' },
                    this.getAdressText(),
                    { text: '', style:'normalText' },
                    { text: '', style:'normalText' },
                    { text: '', style:'normalText' },
                     { text: '', style:'normalText' },
                    { text: '', style:'normalText' },
                    { text: '', style:'normalText' },


                    
                    { text: 'Wir freuen uns auf deinen Anruf!', style:'normalText' },
                    { text: 'Dein Zaunteam', style:'normalText' },
                    
                  
                    ],
                    footer: {table: {
        widths: ['*'],
        body: [[ { border: [false, false, false, false],  height: '200',
                            margin: [0, 0, 0, 0],
                        
                        columns: [
                        {
                          // auto-sized columns have their widths based on their content
                          width: '*',
                          text: {text:'www.zaunteam.com', style:'footerLeft'}
                        },
                        {
                          // star-sized columns fill the remaining space
                          // if there's more than one star-column, available width is divided equally
                          width: '*',
                          text: {text:'Gratis-Tel. 0800 84 86 888', style:'footerRight'}
                        }], style:'footerCol',
                    
                    
                    }
                       ]]
   }, style:'footer',
                    
}, style:'footer',
                    
                    
                    styles: {
                        headerImage: {
                            margin: [0, 40, 40, 0],
                            alignment:'right'
                        },
                        fenceImage: {
                            margin: [40, 10, 10, 10],
                            alignment:'left'
                        },
                        header: {
                            margin: [40, 80, 20, 40],
                            fontSize: 18,
                            bold: true
                        },
                        header2: {
                            margin: [40, 20, 20, 10],
                            padding:5,
                            fontSize: 14,
                            bold: false
                          },
                          normalText: {
                              margin: [40, 5, 40, 0],
                              padding:5,
                              fontSize: 12,
                              bold: false,
                            },
                            normalTextImage: {
                                margin: [40, 30, 40, 0],
                                padding:5,
                                fontSize: 12,
                                bold: false,
                        
                              },  
                            boldText: {
                                margin: [40, 0, 40, 0],
                                padding:5,
                                fontSize: 12,
                                bold: true,
                           
                              },
                          
                        footerRight: {
                            padding:15,
                            height:200,
                            alignment:'center',
                        
                                             
                          },
                          footerLeft: {
                            padding:15,
                             height:200,
                            alignment:'center',
                         
                           
                         
                           },
    
                           footer: {
         
                            fillColor:'#768f0d',
                         
                           },

                           footerCol: {
                        
                            fillColor:'#768f0d',
                            color:'white',
                            border: [false, false, false, false],
                            height:200,
                       
                            margin: [0, 0, 0, 0],
                           
                         
                           },
                    
                  }
                }
        
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                pdfMake.createPdf(dd).download("Zaunprojekt"+this.state.name+".pdf");






                
            })
        })
      
        
        
        //this.setState({ dialogOverviewOpen: true });


    

        
    };
     toDataUrl = (file,callback) => {
        var xhr = new XMLHttpRequest();
           xhr.responseType = 'blob';
           xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
           callback(reader.result);
        }
          reader.readAsDataURL(xhr.response);
       };
        xhr.open('GET', file);
        xhr.send();
        }

    getFenceImageSrc = () => {

        var folder = DataManager.getFromLocalStorage("fenceType")
        var variation = DataManager.getFromLocalStorage("fenceTypeVariation")
        var imageSrc = this.textureUrl + "/" + folder + "/" + variation + "/element.png"

        return imageSrc

    }

    getAlert() {

        this.setState({ random: this.state.random + 1 });
    }
    getFenceCatName = () => {
        var fenceCat = Number(DataManager.getFromLocalStorage("fenceCat"))
        var fenceCatName = ""
        switch (fenceCat) {
            case 0:
                fenceCatName = "Heim & Garten"
                break;
            case 1:
                fenceCatName = "Sicht-/Lärmschutz"
                break;
            case 2:
                fenceCatName = "mehr Sicherheit"
                break;
            case 3:
                fenceCatName = "Tierhaltung"
                break;
            default:
                break;
        }

        return fenceCatName

    }

    getFenceHeight = () => {
        var fenceHeight = Number(DataManager.getFromLocalStorage("fenceHeight"))
        return fenceHeight / 200
    }

    getFenceLength = () => {
        var fenceLength = Number(DataManager.getFromLocalStorage("fenceLength")).toFixed(2)

        return fenceLength

    }



    sendEmail = async () => {


        var text = "Guten Tag "+this.state.name
        text = text+"<br></br>"
        text = text+"Dein Zaunteam Projekt kannst Du unter folgendem Link abrufen:"
        text = text+"<br></br>"
        text = text+"https://planer.zaunplaner.services/?fb="+this.state.key
        text = text+"<br></br>"
        text = text+"Dein Zaunteam Projekt kannst Du 30 Tage lang bearbeiten und speichern. Solltest Du offene Fragen haben oder eine persönliche Beratung wünschen, dann wähle die kostenlose Servicenummer 0800 - 84 86 888. Du wirst direkt mit dem nächstgelegen Zaunteam Standort verbunden."
        text = text+"<br></br>"
        text = text+this.getAdressTextMail()
        text = text+"<br></br>"
        text = text+"Freundliche Grüsse"
        text = text+"<br></br>"
        text = text+"Dein Zaunteam"
        text = text+"<br></br>"
        text = text+"Gratistelefon 0800 84 86 888"
        text = text+"<br></br>"
        text = text+"www.zaunteam.com"
        text = text+"<br></br>"
        text = text+"info@zaunteam.com"

        const data = { fromEmail: "info@zaunapp.com", toEmail: this.state.email, message: text, subject: "Zaunteam Zaunplaner: Dein Projekt ist gespeichert" };
//https://us-central1-zaunplaner-1525772594816.cloudfunctions.net/sendMail
//https://us-central1-training01-26482.cloudfunctions.net/sendMail
        Axios.post("https://us-central1-zaunplaner-1525772594816.cloudfunctions.net/sendMail", data).catch(error => {
            console.log(error);
        });
    }

    getIsVisible = () => {
        return 'hidden'
    }

    getZauntypName = () => {
        var fenceType = DataManager.getFromLocalStorage("fenceType")
        if (fenceType !== undefined && fenceType.length > 0) {
               return  DataManager.getFenceTypeData(fenceType)[0].Zauntyp
            } else {
                return ""
            }
        }
    render(props) {
        const classes = this.props

        const buttonClassname = clsx({
            [classes.buttonSuccess]: this.state.success,
        });

        return (

            <div className="row">
{/*
                <div className="col-3 col-s-3">
                    <div className="ContactFormInfosContainer" id="capture" >

                        <h3>Zusammenfassung</h3>

                        <div className="ContactFormInfosContainerDetails">
                            <div>Anwendung: {this.getFenceCatName()}</div>
                            <div>Zaunlange: {this.getFenceLength()}m</div>
                            <div>Zaunhöhe: {this.getFenceHeight()}m</div>

                        </div>

                    </div>
                </div>*/
}

                <div className="col-5 col-s-5">
                    <form id="formMain" onSubmit={this.onSubmit} className={classes.container} autoComplete="on">
                        <h3>Kontaktieren Sie uns</h3>
                      
                        <TextField
                            id="standard-name"
                            name="name"
                            label="Vor-/Nachname:"
                            className={classes.textField}
                            value={this.state.name}
                            onChange={this.onChange}
                            margin="normal"
                            required="true"
                        />

                        <TextField
                            id="standard-street"
                            name="street"
                            label=" Strasse/Nr.:"
                            className={classes.textField}
                            value={this.state.street}
                            onChange={this.onChange}
                            margin="normal"
                            required="true"
                        />
                        <div className="combinedFields">             <TextField
                            id="standard-plz"
                            name="plz"
                            label="PLZ:"
                            className={classes.textField}
                            value={this.state.plz}
                            onChange={this.onChange}
                            margin="normal"
                            required="true"
                            type="Number"
                        />

                            <TextField
                                id="standard-city"
                                name="city"
                                label="Ort:"
                                className={classes.textField}
                                value={this.state.city}
                                onChange={this.onChange}
                                margin="normal"
                                required="true"
                            /></div>


                        <TextField
                            id="standard-country"
                            name="country"
                            label="Land:"
                            className={classes.textField}
                            value={this.state.country}
                            onChange={this.onChange}
                            margin="normal"
                            required="true"
                        />

                        <TextField
                            id="standard-phone"
                            name="phone"
                            label="Telefon:"
                            className={classes.textField}
                            value={this.state.phone}
                            onChange={this.onChange}
                            margin="normal"

                            type="Number"
                        />

                        <TextField
                            id="standard-email"
                            name="email"
                            label="E-Mail:"
                            className={classes.textField}
                            value={this.state.email}
                            onChange={this.onChange}
                            margin="normal"
                            required="true"
                            type="email"
                        />


                        <TextField
                            id="standard-usercomment"
                            name="usercomment"
                            multiline
                            rowsMax="4"
                            label="Bemerkungen:"
                            className={classes.textField}
                            value={this.state.usercomment}
                            onChange={this.onChange}
                            margin="normal"

                        />

                        <div className="combinedFields">
                            <FormControlLabel
                                value="top"

                                control={<Checkbox color="primary" defaultChecked />}
                                label="Newsletter abonnieren "
                                labelPlacement="right"
                            />
                            <FormControlLabel
                                value="datenschutz"
                                control={<Checkbox color="primary" required="true" />}
                                label={<div><a href='https://www.zaunteam.ch/de/meta/datenschutz/'>Datenbestimmungen</a> akzeptieren</div>}
                                required="true"
                                labelPlacement="right"
                            /></div>
                        <br></br>
                        <div className={classes.root}>
                        <div style={{ visibility: this.state.canLoadPDF ? 'visible' : 'hidden' }}>
                                <Button color="primary" onClick={this.handleOpenDialogOverview}>
                                Zusammenfassung als PDF
                                </Button>
                            </div>
                            <div className={classes.wrapper}>
                                <Button type="submit" variant="contained" color="primary" disabled={this.state.loading} className={buttonClassname} onClick={() => this.handleChangeContactForm('contactForm')}>
                                    Senden
                                </Button>
                                {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                          
                        </div>
                    </form>

                </div>

                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Daten übermittelt</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        Vielen Dank. Dein Zaunprojekt wurde gespeichert. Wie senden Dir eine Bestätigung mit Zugang zum Projekt per E-Mail
            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            Schliessen
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog fullScreen open={this.state.dialogOverviewOpen} onClose={this.handleCloseDialogOverview} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleCloseDialogOverview} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Zusammenfassung
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DetailOverView className="DetailOverView" />
                </Dialog>

            </div>
        )
    }
}

export default ContactForm;