import React, { Component } from 'react'

class Anleitung extends React.Component {
    constructor(props) {
        super(props);
        this.state = {width: 0,
        height: 0, };

     
    }

    render() {


         return (<div className='Anleitung'>Mit diesem Zaunplaner kannst Du den Grundriss Deines Zaunes zeichnen und so ganz bequem ein unverbindliches und kostenloses Angebot anfordern. <br></br> Wähle den Bereich, das Zaunkleid und die Höhe deines Zaunes. Zeichne ihn nachher auf der Basis eines Satellitenbildes, eines Rasters oder selber hochgeladenen Planes. Füge ein Foto ein um die Wirkung des Zaunes in der Umgebung zu sehen. Dein Zaunteam kann zudem auf Grund des Fotos das Terrain besser einschätzen.<br></br>Bei jedem Schritt gibt es oben rechts ein Fragezeichen, welches Dir mehr Unterstützung bietet.<br></br><br></br>Viel Spass, Dein Zaunteam</div>
         )
         }
}

export default Anleitung