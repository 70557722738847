import React, { Component } from 'react'


import axios from 'axios'

//http://www.zaunteam.com/magento-schnittstelle/plzsuche_xml.php?plz=8000&land=CH
class PartnerFinder extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: '', items: Element, testItems:Element,
        width: 0,
        height: 0, };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        const height =
          window.innerHeight -
          document.getElementById("ViewHeaderContainer").clientHeight;
        this.setState({ width: window.innerWidth, height: height });
      }
    
      componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
      }
//Basic c2lyaWx1bmFAZ21haWwuY29tOm5vS2ZvZy1reWZrdXctM25pYmhv
    handleSubmit(event) {

 
        var _this = this;

        var convert = require('xml-js');


        axios.get('https://www.zaunteam.com/magento-schnittstelle/plzsuche_xml.php?plz='+this.state.value+'&land=CH', {crossdomain:true})
            .then(function (res) {

                var result1 = convert.xml2js(res.data, { compact: true, spaces: 0 });

                console.log(result1.partner.name1);
                // console.log("DATA " + result1)
                _this.setState({
                    items: result1
                });

            })

        event.preventDefault();

    }
    handleParnerFinder = buttonId => {
        this.props.handleParnerFinder(buttonId)
    };


    onStartSearch() {
        console.log("FIELD VALUE: ")
    }

    list = ["a", "b", "c"]
openBrowser(urlString){

    /*require('electron').shell.openExternal(
        urlString,
    );*/

}


    render() {


       /* const Tada = "asd"
        const _this = this
        const GetObjectName = (proto) => {

            //  var string = '<div>TEST</div>'

            var res = ""
            if (_this.state.items.partner != undefined) {
                console.log("adsfasdfasdf " + _this.state.items.partner.name1._text)


                var item = _this.state.items.partner

                return (
                    <div className='ContainerPartnerInfo'>

                        <div>{item.name1._text}</div>
                        <div>{item.name2._text}</div>
                        <div>{item.strasse._text}</div>
                        <div>{item.plz._text} {item.ort._text}</div>
                        <p></p>
                        <div>{item.tel._text}</div>
                        <div>{item.fax._text}</div>
                        <div><a href={'mailto:' + item.mail._text}>Mail</a></div>
                        <div><a href={this.openBrowser(item.link._text)} target='new'>Webseite</a></div>

                    </div>)
            }

            return ""
        };

*/

        //  console.log("REMOTE DATA TO RENDER: " + this.getObjectName())
        return (



            <iframe key={this.state.random} title="asd" className="PartnerFinderContainer" src='https://www.zaunteam.ch/de/ihr-partner/' style={{ height: this.state.height, width:this.state.width }}/>  
        )
        }
}

export default PartnerFinder