import React from 'react'
import zaunteam_logo_de from '../media/zaunteam_logo_de.png'
import PropTypes from "prop-types";

import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';

import Chip from '@material-ui/core/Chip';
import { DataManager }  from '../DataManager'

class Header extends React.Component {

  testValue = "asdasdasd"
  newProjectID = ""

  constructor(props) {
    super(props);

    this.state = {
      loginTitle: '',
      loginDialogOpen: false,
      test:"test",
      deleteDialogOpen:false,
      fenceReady:false
    };
  }

  getAlert() {
    alert('getAlert from Child');
  }
  componentDidMount() {
    this.getLoginTitle()
  }
  componentWillUnmount() {
   
  }
  handleClick = buttonName => {
    this.props.clickHandler(buttonName);
  };

  myCallback = (dataFromChild) => {
  }

  loginCallback = () => {
  }

  handleNewProjectId = () => {
    console.log("NEW ID")
    this.getLoginTitle()
  }

  handleClickOpen = () => {
    console.log("OPEN")
    this.setState({ loginDialogOpen: true });
  };

  handleCancelLoad = () => {
    this.setState({ loginDialogOpen: false });

  };

  handleDeleteDialogOpen = () => {
    console.log("OPEN")
    this.setState({ deleteDialogOpen: true });
  };

  handleCloseDeleteDialog = () => {
    this.setState({ deleteDialogOpen: false });

  };

  handleDeleteDialog = (deleteOnServer) => {
  console.log("handleDeleteDialog: "+deleteOnServer)
      this.props.deleteProjectHandler(deleteOnServer)
  };

  handleLoadProjectId = () => {
    DataManager.saveToLocalStorage("boardId", this.newProjectID.trim())
    /*Get current URL*/
    var _url = window.location.href;
    _url = _url.substring(0, _url.lastIndexOf('/'));

    /*if(_url.indexOf('?') !== -1) {
      _url = _url.split("?")[0]
    }
    
    _url = _url+'?fb='+this.newProjectID.trim()
    
    /*reload the page */

    window.location.href = _url;

    this.getLoginTitle()
  }
componentDidUpdate(newProps){
  //this.setState({loginTitle: newProps.loginTitle});
}

  handleLoginTextFieldChange = (event) => {

    this.newProjectID = event.target.value;

  };

  checkFence = () => {

    var path = DataManager.getMapFenceDataFromLocalStorage()

    if (path != null && path.length > 0) {
   return true
    }

    return false
  }

  getLoginTitle = () => {

    var loginTitle = ""
    var boardId = DataManager.getFromLocalStorage("boardId")
    if (boardId != null && boardId !== "") {
      loginTitle = boardId
    }
    this.setState({ loginTitle: loginTitle });
  }

  handleComponentClick(param) {
    console.log("asdfasdfasdf")
  }
  render() {
    return (
      <div className="ViewHeaderContainer" id="ViewHeaderContainer">
        <header className="AppHeader">
        
          {/*<div className="HeaderLogo">
            <img id='imgLogo' src={zaunteam_logo_de}></img>
           
          </div>
         
          <div className="HeaderTitle">
          
     
           
            
       
          </div>*/}
          
              <Chip
              id="btnLogin"
                label={"Projekt bearbeiten "}
                onClick={this.handleClickOpen}
             
                color="primary"
                avatar={<Avatar><EditIcon /></Avatar>}
              />
              <p id='txtTitle'>Zaunplaner {this.appTitle}</p>
            
        </header>

        <div className="HeaderButtonGroup">

          <NavLink exact activeClassName="selected" to="/" >Start</NavLink>
          <NavLink exact activeClassName="selected" to="/choose">Zaun wählen</NavLink>
          <NavLink exact activeClassName="selected" to="/Plan" > planen</NavLink>
          <NavLink exact activeClassName="selected" className={this.checkFence() ? '':'disabled'} to="/Visualize" >visualisieren</NavLink>
          <NavLink exact activeClassName="selected" className={this.checkFence() ? '':'disabled'} to="/ContactForm" >anfragen</NavLink>
        
            
            <div id="txtVersion"> Version 1.110 / 28.07.2020 </div>
        </div>
        <Dialog
          open={this.state.loginDialogOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
           <DialogTitle id="form-dialog-title">  Aktuelle Projekt ID</DialogTitle>
          <DialogContent>
            <DialogContentText>
            {this.props.updatedBoardId}
            </DialogContentText>
           
          </DialogContent>
          <DialogTitle id="form-dialog-title">Projekt laden</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Bitte die gewünschte Projekt ID eingeben.
            </DialogContentText>
            <TextField
              onChange={this.handleLoginTextFieldChange}
              autoFocus
              margin="dense"
              id="name"
              label="Projekt ID"
              type="text"
              fullWidth
            />
       
          </DialogContent>
          <DialogActions>
            <Button class="form-dialog-wide-button" onClick={this.handleLoadProjectId} color="primary">
              Laden
            </Button>
          </DialogActions>
          <DialogTitle id="form-dialog-title">Neues Projekt</DialogTitle>
          <DialogContent>
        
            <div className="form-dialog-delete-options">
            <Button onClick={() => this.handleDeleteDialog(false)} color="primary">
              Neuses Projekt erstellen
            </Button>
            <Button  onClick={this.handleDeleteDialogOpen} color="secondary">
              Löschen
            </Button>
            </div>
       
          </DialogContent>
          <DialogActions>
            <Button class="form-dialog-wide-button" id="cancel-button" onClick={this.handleCancelLoad} >
              Abbrechen
            </Button>
       
            </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.deleteDialogOpen} aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Wirklich löschen?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Soll das Projekt wirklich gelöscht werden? Das Projekt wird auch auf dem Server gelöscht und kann nicht wieder hergestellt werden.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={() => this.handleDeleteDialog(true)} color="secondary">
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
Header.propTypes = {
  clickHandler: PropTypes.func,
  handleNewProjectId:PropTypes.func,
  loginTitle:""
};
export default withRouter(Header)